import nl from './nl.js'
import en from './en.js'
import de from './de.js'
import ch from './ch.js'
import fr from './fr.js'
import it from './it.js'

let lang = {}
const setUpLang = () => {
  const appLang = process.env.VUE_APP_LANG
  if (localStorage.getItem('lang')) {
    localStorage.setItem('lang', localStorage.getItem('lang'))
  } else {
    localStorage.setItem('lang', appLang)
    lang = appLang
  }

  if (!localStorage.getItem('currentLocale')) {
    localStorage.setItem('currentLocale', appLang)
  }

  return lang
}

setUpLang()

const messages = {
  en,
  de,
  nl,
  ch,
  fr,
  it,
}

export default messages
