import ApiService from './api.service'

const PriceService = {
  async newPrice(data) {
    try {
      return await ApiService.post('prices', data)
    } catch (error) {
      return error.response
    }
  },

  async prices(page = 1) {
    try {
      return await ApiService.get('prices?page=' + page)
    } catch (error) {
      return error
    }
  },

  async deletePriceById(slideId) {
    try {
      const response = await ApiService.delete('prices/' + slideId)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async price(id) {
    try {
      return await ApiService.get('prices/' + id + '/show/langs')
    } catch (error) {
      return error.response
    }
  },

  async updatePrice(data, id) {
    try {
      ApiService.setHeader('application/x-www-form-urlencoded')
      return await ApiService.post('prices/' + id, data)
    } catch (error) {
      return error.response
    }
  },

  async updatePriceList(data, id) {
    try {
      return await ApiService.post('price-categories/' + id, data)
    } catch (error) {
      return error.response
    }
  },

  async priceCategories() {
    try {
      return await ApiService.get('priceCategories')
    } catch (error) {
      return error.response
    }
  },

  async newPriceList(data) {
    try {
      return await ApiService.post('price-categories', data)
    } catch (error) {
      return error.response
    }
  },

  async priceList(page) {
    try {
      return await ApiService.get('price-categories?page=' + page)
    } catch (error) {
      return error.response
    }
  },

  async deletePriceListById(id) {
    try {
      const response = await ApiService.delete('price-categories/' + id)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async singlePriceList(id) {
    try {
      return await ApiService.get('price-categories/' + id + '/show/langs')
    } catch (error) {
      return error.response
    }
  },

  async newPriceListFile(data) {
    try {
      return await ApiService.post(
        `price-categories/${data.price_list_id}/files`,
        data,
      )
    } catch (error) {
      return error.response
    }
  },

  async newPriceFile(data) {
    try {
      return await ApiService.post(`prices/${data.price_id}/files`, data)
    } catch (error) {
      return error.response
    }
  },

  async brochureCategories() {
    try {
      return await ApiService.get('brochure-categories')
    } catch (error) {
      return error.response
    }
  },

  async newBrochure(data) {
    try {
      return await ApiService.post('admin/brochure/create', data)
    } catch (error) {
      return error.response
    }
  },

  async brochures(page) {
    try {
      return await ApiService.get(`admin/brochure/list?page=${page}`)
    } catch (error) {
      return error.response
    }
  },

  async deleteBrochureById(id) {
    try {
      const response = await ApiService.delete(`admin/brochure/${id}/delete`)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async loadBrochureById(id) {
    try {
      const response = await ApiService.get(`admin/brochure/${id}/show`)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async brochureFiles(id) {
    try {
      return await ApiService.get(`brochure/${id}/files/langs`)
    } catch (error) {
      return error.response
    }
  },

  async newBrochureFile(data) {
    try {
      return await ApiService.post(
        `admin/brochure/${data.brochure_id}/files`,
        data,
      )
    } catch (error) {
      return error.response
    }
  },

  async updateBrochure(data) {
    try {
      const response = await ApiService.put(
        `admin/brochure/${data.id}/update`,
        data,
      )
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async storeBrochureFiles(data, id) {
    try {
      ApiService.setHeader('application/x-www-form-urlencoded')
      return await ApiService.post('admin/brochure/files/' + id, data)
    } catch (error) {
      return error.response
    }
  },

  async deleteBrochureFile(id, origin) {
    try {
      const response = await ApiService.delete(
        `admin/brochure/${id}/file/${origin}`,
      )
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async fetchFilteredBrochure(data) {
    return await ApiService.post(`brochure/filter`, data)
  },
}

export { PriceService }
