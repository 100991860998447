import ApiService from './api.service'

const SlideService = {
  async newSlide(data) {
    try {
      return await ApiService.post('slides', data)
    } catch (error) {
      return error.response
    }
  },
  async slides(page = 1) {
    try {
      return await ApiService.get('slides?page=' + page)
    } catch (error) {
      return error.response
    }
  },
  async slide(id) {
    try {
      return await ApiService.get('slides/' + id + '/show/langs')
    } catch (error) {
      return error.response
    }
  },
  async updateSlide(data, id) {
    try {
      return await ApiService.put('slides/' + id, data)
    } catch (error) {
      return error.response
    }
  },
  async deleteSlideById(slideId) {
    try {
      const response = await ApiService.delete('slides/' + slideId)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async reorderSlider(data) {
    try {
      return await ApiService.post('slides/reorder', data)
    } catch (error) {
      return error.response
    }
  },
}

export { SlideService }
