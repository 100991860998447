import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import users from '@/router/users'
import products from '@/router/products'
import commands from '@/router/commands'
import trainings from '@/router/trainings'
import management from '@/router/management'
import messages from '@/router/messages'
import requests from '@/router/requests'
import points from '@/router/points'
import store from '../store'
import logs from '@/router/logs'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/pages/Login'),
  },
  {
    path: '/dashboard',
    name: 'home',
    component: DefaultLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
          content: () =>
            import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        },
      },
      {
        path: '/users-roles',
        name: 'users-roles',
        meta: {
          isParent: true,
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
        children: [...users],
        redirect: '/users-roles/roles',
      },
      {
        path: '/products-categories',
        name: 'products-categories',
        meta: {
          isParent: true,
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'en', 'ch', 'nl'],
        },
        children: [...products],
        redirect: '/products-categories/categories',
      },
      {
        path: '/commands',
        name: 'commands',
        meta: {
          isParent: true,
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
        children: [...commands],
        redirect: '/commands/ipc',
      },
      {
        path: '/trainings-schulungen',
        name: 'trainings-schulungen',
        meta: {
          isParent: true,
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
        children: [...trainings],
        redirect: '/trainings-schulungen/trainings',
      },
      {
        path: '/management',
        name: 'management',
        meta: {
          isParent: true,
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
        children: [...management],
        redirect: '/management/textpages',
      },
      {
        path: '/settings',
        name: 'settings',
        meta: {
          isParent: true,
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
        children: [...messages],
        redirect: '/settings/messages',
      },
      {
        path: '/requests',
        name: 'requests',
        meta: {
          isParent: true,
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
        children: [...requests],
        redirect: '/requests/product-trainings-request',
      },
      {
        path: '/points',
        name: 'points',
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
          isParent: true,
        },
        children: [...points],
        redirect: '/points/cheques',
      },

      {
        path: '/logs',
        name: 'logs',
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          isParent: true,
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
        children: [...logs],
        redirect: '/logs/activities',
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  // Check required role
  let requiredRole = to.meta.requiredRole
  let requiredLang = to.meta.requiredLang
  const lang = localStorage.getItem('lang')

  if (requiredRole) {
    try {
      await store.dispatch('me')
      let user = store.getters.getAdminRole
      let role = user.find((item) => requiredRole.includes(item.name))
      if (!role || !requiredLang.includes(lang)) {
        next('/dashboard')
        return
      }
    } catch (e) {
      console.log(e)
      next('/dashboard')
      return
    }
  }

  next()
  return
})

export default router
