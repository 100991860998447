import ApiService from './api.service'

const PointSettingsService = {
  async loadPointSettings() {
    try {
      return await ApiService.get('point-settings')
    } catch (e) {
      return e.response
    }
  },
}

export { PointSettingsService }
