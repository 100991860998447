import de from './locales/de'
import en from './locales/en'
import ch from './locales/ch'
import pointsImporterStore from './store'

export default {
  install: async (app, options) => {
    options.store.registerModule('pointsImporterStore', pointsImporterStore)

    options.i18n.global.mergeLocaleMessage('de', de)
    options.i18n.global.mergeLocaleMessage('ch', ch)
    options.i18n.global.mergeLocaleMessage('en', en)
  },
}
