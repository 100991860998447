const actions = {
  setPointType({ commit, }, pointType) {
    commit('SET_POINT_TYPE', pointType)
  },
  setExtraPoints({ commit, }, extraPoints) {
    commit('SET_EXTRA_POINTS', extraPoints)
  },
  unsetImporterState({ commit, }, importState) {
    commit('UNSET_IMPORTER_STATE', importState)
  },
  setImporterState({ commit, }, importState) {
    commit('SET_IMPORTER_STATE', importState)
  },
  setSelectedUser({ commit, }, selectedUser) {
    commit('SET_SELECTED_USER', selectedUser)
  },
  setExcelUsers({ commit, }, excelUsers) {
    commit('SET_EXCEL_USERS', excelUsers)
  },
  setFetchedUsers({ commit, }, fetchedUsers) {
    commit('SET_FETCHED_USERS', fetchedUsers)
  },
  setExtraImportPoints({ commit, }, extraImportPoints) {
    commit('SET_EXTRA_IMPORT_POINTS', extraImportPoints)
  },
  setImportPoints({ commit, }, importPoints) {
    commit('SET_IMPORT_POINTS', importPoints)
  },
  setImporterType({ commit, getters }, type) {
    commit(
      'SET_IMPORTER_TYPE',
      getters.importerTypes.find((importer) => importer.id === type),
    )
  },
  setImporterTypes({ commit }, response) {
    commit('SET_IMPORTER_TYPES', response.data)
  },
}

export { actions }
