import ApiService from './api.service'

const RoleService = {
  async removePoints(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-points`)
    } catch (error) {
      return error.response
    }
  },
  async removeStaffConnection(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-staff-connection`)
    } catch (error) {
      return error.response
    }
  },
  async removeCsNr(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-cs-numbers`)
    } catch (error) {
      return error.response
    }
  },
  async removeOrders(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-orders`)
    } catch (error) {
      return error.response
    }
  },
  async removeWshConnection(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-wsh-connection`)
    } catch (error) {
      return error.response
    }
  },
  async removeUberalls(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-uberalls`)
    } catch (error) {
      return error.response
    }
  },
  async removeTrainings(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-trainings`)
    } catch (error) {
      return error.response
    }
  },
  async removeBTrainings(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-btrainings`)
    } catch (error) {
      return error.response
    }
  },
  async removeExternalTrainings(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-external-trainings`)
    } catch (error) {
      return error.response
    }
  },
  async removeHunters(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-hunters`)
    } catch (error) {
      return error.response
    }
  },
  async removeUserPromotions(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-user-promotions`)
    } catch (error) {
      return error.response
    }
  },
  async removeSalesPostalCodes(id) {
    try {
      return await ApiService.delete(`roles/${id}/delete-sale-postal-code`)
    } catch (error) {
      return error.response
    }
  },

  async saveRole(data, id) {
    try {
      return await ApiService.put(`roles/${id}/update`, data)
    } catch (error) {
      return error
    }
  },

  async roles() {
    try {
      return await ApiService.get('roles')
    } catch (error) {
      return error
    }
  },

  async rolesSelect() {
    try {
      return await ApiService.get('roles/select')
    } catch (error) {
      return error
    }
  },

  async rolesUsers(id) {
    try {
      return await ApiService.get('roles/' + id + '/users')
    } catch (error) {
      return error
    }
  },

  async role(id) {
    try {
      return await ApiService.get('roles/' + id)
    } catch (error) {
      return error
    }
  },
}

export { RoleService }
