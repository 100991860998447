export default {
  message: {
    display_points: 'Laat de gebruiker marketingpunten zien',
    display_kickbacks: 'Laat de gebruiker de bonus zien',
    exportDataTitle: 'Lijsten als Excel-bestand downloaden',
    mobile_phone: '(Mobiel) Telefoonnummer',
    commitment_letter_accept: 'Commitment letter accept',
    iban_number: 'IBAN-nummer van uw bedrijf',
    without_supplier: 'Zonder leverancier',
    price_in_euro: 'Prijs in €',
    is_default: 'Standaard',
    attribute_option: 'attribuut optie',
    image: 'afbeelding',
    show_stats_user: 'Winkelstatistieken - GEBRUIKER',
    is_manual_order: 'Is een handmatige bestelling',
    not_available: 'Momenteel beschikbaar',
    supplier_id: 'Leverancier ID',
    show_empty: 'Toon producten met aantal = 0',
    vendor_id: 'Fabrikant-ID',
    archive_at: 'Archief',
    exported_bridgestone: 'Exportiert Bridgestone',
    exported_engelbert: 'Exportiert Engelbert Strauß',
    exported_prime: 'Exportiert PrämieDirekt',
    exported_other: 'Andere geëxporteerd',
    product_variant_id: 'Productvariant-ID',
    cancelled_at: 'Geannuleerd om',
    shipped_at: 'Verzonden om',
    product_id: 'Product-ID',
    quantity: 'Hoeveelheid',
    qty: 'Hoeveelheid',
    action_text: 'URL-tekst',
    action_url: 'URL',
    token: 'UUID',
    marketing_option: 'Marketingoptie',
    parent_id: 'Ouder ID',
    approval_id: 'Goedkeurings-id',
    wholesaler_id: 'Wholesaler id',
    cart_id: 'Winkelwagen-ID',
    order_date: 'Besteldatum',
    order_number: 'Bestellingsnummer',
    accept_date: 'Datum accepteren',
    wholesaler_dealer_id: 'Wholesaler dealer id',
    dealer_id: 'Dealer id',
    is_partial_delivery: 'Betreft gedeeltelijke levering',
    order_completed: 'Bestelling voltooid',
    order_cancelled: 'Bestelling geannuleerd',
    date_delivery: 'Bezorgdatum',
    published: 'Publiceren',
    properties: 'Eigenschappen',
    group: 'Groepsnaam',
    gka: 'GKA',
    publish_at: 'Publiceren',
    video: 'Video URL',
    training_id: 'Trainings-ID',
    correct: 'Is juist?',
    question_id: 'Vraag-ID',
    body: 'Inhoud',
    size: 'Lettergrootte',
    platform: 'Besturingssysteem',
    is_added: 'Actieve booster',
    numberOfSetsOfSeasonalTiresBudget: 'opslag per jaar',
    distributionWinter: 'Winter',
    distributionSummer: 'Zomer',
    overEighteenInch: '≥ 18 inch (in %)',
    tireSalesNumber: 'Bandenverkoop per jaar alle bandenmerken.',
    job_position_id: 'Functie in het bedrijf',
    distributionAll: 'All season',
    budgetPremium: 'Premium',
    postal_code: 'Postcode',
    legalForm: 'Wettelijk document',
    budgetMid: 'Midden',
    budgetLow: 'Budget',
    group_id: 'Groeps-ID',
    website: 'website',
    customer_number: 'Klantnummer',
    user_id: 'gebruikersnaam',
    browser: 'webbrowser',
    ip: 'IP adres',
    email_verified_at: 'e-mailadres geverifieerd op',
    last_login: 'Laatste aanmelding',
    deleted_at: 'verwijderd bij',
    is_active: 'Is actief?',
    old: 'Oud',
    selectBooster: 'Selecteer een booster',
    activeBooster: 'Actieve booster',
    activeBoosterText: 'Wilt u een booster voor de gebruiker activeren?',
    activeBoostersOne: 'Ga je deze booster voor iedere gebruiker activeren?',
    sum: 'Som',
    boosterOnTop: 'Boosterpunten (bovenaan)',
    relevantBoosterPoints: 'Relevante boosterpunten',
    totalBefore: 'Puntentotaal voorheen',
    standardPoints: 'Standaard punten',
    boosterPoints: 'Boosterpunten',
    allPoints: 'Alle punten',
    pointsByUser: 'Punten per gebruiker downloaden',
    pointsByGroup: 'Punten per groep downloaden',
    loginAs: 'Log in als gebruiker',
    positionX: 'Positie x',
    positionY: 'Positie y',
    textSize: 'Lettergrootte',
    text: 'Tekst',
    publish: 'Publiceren',
    certificate: 'Certificaat',
    remove_cs_number: 'Weet u zeker dat u het klantnummer wilt verwijderen?',
    remove_order: 'Weet u zeker dat u de bestelling wilt verwijderen?',
    is_internal: 'Is interne gebruiker?',
    selectAddressBtn: 'Selecteer adres',
    currentBasket: 'Huidige mand',
    loginEmail: 'Log in',
    open_new_tab: 'Openen in nieuw tabblad?',
    file: 'Bestand',
    company_name: 'Bedrijfsnaam',
    partner_name: 'Contactpersoon',
    boosterImport: {
      label: 'CSV-bestand.',
    },
    event: 'Evenement',
    created_at: 'Creatiedatum',
    dealer: 'Dealer',
    dealers: 'Car Dealer / Tyre Dealer',
    activity: 'Activiteit',
    logs: 'Logboeken',
    full_text_search: 'Zoek volledige text',
    booster_wholesaler: 'Groothandelaar',
    booster_saison: 'Seizoen',
    booster_create: 'Booster maken',
    booster_tires: 'Banden',
    booster_products: 'Productnamen',
    booster_customers: 'Klantnummers',
    booster_factor: 'Faktor',
    booster_ipcs: "IPC's",
    booking: 'Boeking',
    select: 'Selecteer alstublieft',
    preview: 'Voorbeeld',
    credit: 'Credit',
    deduction: 'Debet',
    subject: 'Onderwerp',
    customerNumber: 'Klantnummer',
    search: 'Zoek',
    customer_nr_name: 'Klantnummer/naam',
    selectAll: 'Selecteer alles',
    import: 'Importeren',
    articleNumber: 'Artikel nummer',
    inform_user: 'Om klanten te informeren',
    leaveComment: 'Laat hier een reactie achter',
    user_new: 'Nieuwe gebruiker',
    actions: 'Acties',
    residence: 'Is woonplaats',
    emailVerified: 'E-mailadres geverifieerd op',
    optinAt: 'Aanmelden bij',
    registerAt: 'Schrijf je in bij',
    street: 'Straat & huisnummer',
    country: 'Land',
    postalcode: 'Postcode',
    city: 'Plaats',
    suffix: 'Adresachtervoegsel',
    fax: 'Fax',
    date: 'Datum',
    optinToken: 'OptIn-Token',
    title: 'Titel',
    phone: 'Telefoon',
    company: 'Naam bedrijf',
    forename: 'Voornaam',
    lastname: 'Achternaam',
    language: 'Taal',
    salutation: 'Aanhef',
    userData: 'Gebruikersgegevens',
    profile: 'Profiel',
    addresses: 'Adressen',
    address: 'Adres',
    vendor_new: 'Nieuwe leverancier',
    supplier_new: 'Nieuwe leverancier',
    show0Products: 'Toon producten met aantal = 0',
    notAvailable: 'Beschikbaar',
    limitless: 'Grenzeloos',
    isDefault: 'Is standaard?',
    data: 'Gegevens',
    startImport: 'Begin met importeren',
    importText: 'Selecteer een bestand om te importeren:',
    exportText: 'Select the option you want to export:',
    upload: 'Uploaden',
    vat: 'BTW-nummer van uw bedrijf',
    uploadText: 'Upload een afbeelding',
    users: 'Gebruikers',
    save: 'Opslaan',
    downloadOrderStats: 'Statistieken downloaden',
    downloadProductStats: 'Productstatistieken downloaden',
    category: 'Categorie',
    order: 'Volgorde',
    permissions: 'Machtigingen',
    permission: 'Machtiging',
    options: 'Opties',
    attributes: 'Kenmerk',
    add_attribute: 'Attribuut toevoegen',
    login: 'Inloggen',
    logout: 'Uitloggen',
    email: 'E-mail',
    username: 'Gebruikersnaam',
    type: 'Type',
    status: 'Status',
    filter: 'Filter',
    name: 'Naam',
    remove: 'Verwijderen',
    points: 'Punten',
    description: 'Omschrijving',
    short_description: 'Korte beschrijving',
    count: 'aantal',
    action: 'Actie',
    role: 'Rol',
    sku: 'SKU',
    main: 'Hoofdproduct',
    variant: 'Productvariant',
    active: 'Actief',
    vendor: 'Verkoper',
    supplier: 'Leverancier',
    images: 'Afbeeldingen',
    upload_text: "Upload productfoto's",
    user: 'Gebruiker',
    roles: 'Rollen',
    search_products: 'Zoek producten',
    search_orders: 'Zoek bestellingen',
    search_type: 'Zoektype',
    hunter_new: 'Nieuwe hunter',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    copy: 'Kopiëren',
    add_empty_variant: 'Lege variant toevoegen',
    do_inactive: 'Product deactiveren',
    activated: 'Geactiveerd',
    menu: {
      shopLimits: 'Shop limits',
      default_banners: 'Default banners',
      import_adex: 'Adex import',
      adex: 'Adex',
      messages_new: 'Nieuw bericht',
      messages_list: 'Lijst',
      logs: 'Logs',
      activities: 'Activiteiten',
      customer: 'Klant',
      booster_setup: 'Booster-instellingen',
      booster_list: 'Lijst',
      booster: 'Booster',
      points: 'Bonus-/puntenbeheer',
      cheques: 'Bonus-/puntenbeheer',
      'users-roles': 'Rollen en gebruikers',
      roles: 'Rollen',
      users: 'Gebruikers',
      users_list: 'Gebruikerslijst',
      users_deleted: 'Verwijderde gebruikers',
      user_new: 'Nieuwe gebruiker toevoegen',
      perms: 'Machtigingen',
      'product-categories': 'Categorieën en producten',
      categories: 'Categorieën',
      categories_list: 'Categorieënlijst',
      category_new: 'Nieuwe categorie toevoegen',
      orders: 'Bestellingen',
      products: 'Producten',
      products_list: 'Productenlijst',
      product_new: 'Nieuw enkelvoudig product toevoegen',
      product_new_variants: 'Nieuw product met varianten',
      attributes: 'Kenmerk',
      attributes_list: 'Kenmerklijst',
      attribute_new: 'Nieuw kenmerk toevoegen',
      options_list: 'Optielijst',
      options_new: 'Nieuwe optie toevoegen',
      options: 'Opties',
      vendors: 'Verkopers',
      vendors_list: 'Verkoperslijst',
      vendor_new: 'Nieuwe verkoper toevoegen',
      suppliers: 'Leveranciers',
      suppliers_list: 'Leverancierslijst',
      supplier_new: 'Nieuwe leverancier toevoegen',
      sliders: 'Sliders',
      sliders_list: 'Sliderslijst',
      slider_new: 'Nieuwe slider toevoegen',
      featured: 'Uitgelichte producten',
      commands: "Commando's",
      command_ipc: 'IPC',
      exports: 'Exports',
      command_users: 'Gebruiker importeren',
      'trainings-schulungen': 'Trainingen en lezingen',
      trainings: 'Producttrainingen',
      trainings_list: 'Lijst',
      training_new: 'Nieuwe toevoegen',
      btrainings_list: 'Lijst',
      btraining_new: 'Nieuwe toevoegen',
      btrainings: 'BS-trainingen',
      management: 'Contentbeheer',
      textpages: "Tekstpagina's",
      textpages_list: "Lijst met tekstpagina's",
      text_page_new: 'Nieuwe tekstpagina toevoegen',
      settings: 'Berichtenbeheerder',
      messages: 'Berichten',
      threads: 'Threads',
      requests: 'Gebruikersaanvragen',
      product_trainings_request: 'Aanvragen voor producttrainingen',
      bridgestone_courses_request: 'Aanvragen voor Bridgestone-cursussen',
      external_trainings_request: 'Aanvragen voor externe trainingen',
      hunter_request: 'Hunter',
      investments_request: 'Investeringsondersteuning',
      news_feed: 'Nieuwsfeed',
      news_feed_list: 'Nieuwslijst',
      news_feed_new: 'Nieuw nieuws toevoegen',
      salespromotions: 'Verkoopacties',
      imported_products: 'Geïmporteerde producten',
      hunter_list: 'Hunterlijst',
      hunters: 'Hunters',
      hunter_new: 'Nieuwe Hunter',
      groups_list: 'Netwerklijst',
      groups: 'Netwerk',
      group_new: 'Nieuwe',
      history: 'Aanmeldeverlauf',
      history_list: 'Aanmeldgeschiedenis',
      prices: 'Prijzen',
      prices_list: 'Lijst',
      price_new: 'Nieuwe prijs',
      price_categories: 'Prijzenlijsten',
      price_categories_list: 'Lijst',
      price_categories_new: 'Nieuwe prijslijst',
      inactive_users: 'Inactieve gebruikers',
      customer_numbers_to_approve: 'Neuanmeldungen<br/>ohne Nummer',
      complaints_list: ' klachten',
      orders_list: 'Lijst',
      orders_new: 'Nieuwe bestelling',
      points_list: 'Puntenlijst',
      booster_preview: 'Voorbeeld',
      'products-categories': 'Categorieën en producten',
      frontend_menu: 'Frontend-menu',
      frontend_menu_list: 'Lijst',
      frontend_menu_new: 'Nieuwe',
      kickback_list: 'Bonus',
      mediapages: 'Media Pages',
      mediapages_list: 'Lijst',
      media_page_new: 'New',
      uberall_request: 'Uberall',
      history_hour: 'Inloguren',
      wholesaler_management: 'Distributeurs management',
      mediapages_file_list: 'bestandslijst',
      vouchers: 'Vouchers',
      vouchers_list: 'Lijst',
      vouchers_new: 'Nieuwe',
      brochure_prices_list: 'Lijst',
      brochure_prices: 'Productbrochures',
      brochure_prices_new: 'Nieuw',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'Lijst',
      main_box: 'Bewerken',
      main_boxes_new: 'Nieuw',
      'hello-customer-survey': 'Hello Customer Survey',
      surveys_list: 'Surveys List',
      survey_new: 'Add New Survey',
      brochure_file_list: 'Bestandenlijst',
      brochure_price: 'Productbrochures',
      category_externals_list: 'Categorie externe lijst',
    },
    breadcrumb: {
      shopLimits: 'Shop limits',
      default_banners: 'Default banners',
      import_adex: 'Adex import',
      adex: 'Adex',
      history: 'Aanmeldeverlauf',
      activities: 'Activiteiten',
      customer: 'Klant',
      booster_setup: 'Booster-instellingen',
      booster_list: 'Lijst',
      booster: 'Booster',
      cheques: 'Bonus-/puntenbeheer',
      user_edit: 'Gebruiker bewerken',
      user_new: 'Nieuwe gebruiker',
      vendors: 'Verkopers',
      suppliers: 'Leveranciers',
      users: 'Gebruikers',
      user: 'Gebruiker',
      home: 'Home',
      perms: 'Machtigingen',
      roles: 'Rollen',
      roles_list: 'Lijst',
      dashboard: 'Dashboard',
      orders: 'Bestellingen',
      orders_edit: 'Bestelling bewerken',
      orders_view: 'Bestelling',
      orders_list: 'Bestellingen',
      salespromotions: 'Verkoopacties',
      salespromotions_list: 'Lijst',
      salespromotions_view: 'Weergeven',
      'products-categories': 'Bonuswinkel',
      categories: 'Categorieën',
      categories_list: 'Lijst',
      role: 'Rol',
      category: 'Categorie',
      products: 'Producten',
      products_list: 'Lijst',
      product: 'Product',
      product_new: 'Nieuw enkelvoudig product',
      product_new_variants: 'Nieuw product met varianten',
      product_variants: 'Product met varianten',
      product_category: 'Categorie-producten',
      attributes: 'Kenmerk',
      attributes_list: 'Lijst',
      attribute: 'Attribuut',
      category_new: 'Nieuwe categorie toevoegen',
      attribute_edit: 'Attribuut bewerken',
      options_new: 'Nieuwe optie',
      options: 'Opties',
      options_list: 'Lijst',
      userEdit: 'Gebruiker bewerken',
      users_list: 'Lijst',
      users_deleted: 'Verwijderde gebruikers',
      vendor_new: 'Nieuwe verkoper',
      vendors_list: 'Lijst',
      vendor_edit: 'Verkoper bewerken',
      supplier_new: 'Nieuwe leverancier',
      suppliers_list: 'Lijst',
      supplier_edit: 'Leverancier bewerken',
      trainings: 'Trainingen',
      trainings_list: 'Lijst',
      btrainings: 'Online-trainingen',
      btrainings_list: 'Lijst',
      btraining: 'BS-training',
      training_new: 'Nieuwe training',
      btraining_new: 'Nieuwe BS-training',
      training_edit: 'Training bewerken',
      training: 'Training',
      command_ipc: 'IPC',
      exports: 'Exports',
      sliders: 'Sliders',
      sliders_list: 'Lijst',
      slider_new: 'Nieuwe slider',
      slider: 'Slider bewerken',
      command_users: 'Gebruikers importeren',
      textpages: "Tekstpagina's",
      text_page_new: 'Nieuwe tekstpagina',
      textpage: 'Tekstpagina',
      textpages_list: 'Lijst',
      messages: 'Berichten',
      messages_list: 'Lijst',
      messages_new: 'Nieuw bericht',
      messages_edit: 'Bericht bewerken',
      threads: 'Threads',
      featured: 'Uitgelichte producten',
      attribute_new: 'Nieuw kenmerk',
      product_trainings_request: 'Aanvragen voor producttrainingen',
      bridgestone_courses_request: 'Aanvragen voor BS-trainingen',
      external_trainings_request: 'Aanvragen voor externe trainingen',
      hunter_request: 'Hunter',
      investments_request: 'Investeringsondersteuning',
      news_feed: 'Neuigkeiten',
      news_feed_list: 'Liste',
      news_feed_new: 'Nieuwe nieuwsfeed',
      news_feed_edit: 'Nieuwsfeed bewerken',
      news_feed_slider: 'Nieuwe slider voor nieuwsfeed',
      imported_products: 'Geïmporteerde producten',
      hunter_list: 'Lijst',
      hunters: 'Hunters',
      hunter_new: 'Nieuwe Hunter',
      hunter_edit: 'Hunter bewerken',
      groups: 'Netwerk',
      groups_list: 'Netwerklijst',
      group_new: 'Nieuwe groep',
      group_edit: 'Groep bewerken',
      history_list: 'Aanmeldgeschiedenis',
      history_view: 'Aanmeldgeschiedenis van gebruiker',
      prices: 'Prijzen',
      prices_list: 'Lijst',
      price_new: 'Nieuwe prijs',
      price: 'Prijs bewerken',
      price_categories: 'Prijzenlijsten',
      price_categories_list: 'Lijst',
      price_categories_new: 'Nieuwe prijslijst',
      price_category: 'Prijzenlijst',
      inactive_users: 'Inactieve gebruikers',
      inactive_users_list: 'Lijst',
      customer_numbers_to_approve: 'Neuanmeldungen ohne Nummer',
      complaints: 'Klachten',
      complaints_list: 'Lijst',
      complaints_view: 'Klacht',
      orders_new: 'Nieuwe bestelling',
      points_list: 'Puntenlijst',
      booster_preview: 'Voorbeeld',
      frontend_menu: 'Frontend-menu',
      frontend_menu_list: 'Lijst',
      frontend_menu_new: 'Nieuwe',
      options_edit: 'Bewerk optie',
      mediapages: 'Media Pages',
      mediapages_list: 'Lijst',
      media_page_new: 'New',
      mediapage: 'Edit',
      uberall_request: 'Uberall',
      history_hour: 'Inloguren',
      wholesaler_management: 'Distributeurs management',
      mediapages_file_list: 'bestandslijst',
      vouchers: 'Vouchers',
      vouchers_list: 'Lijst',
      vouchers_new: 'Nieuwe',
      brochure_prices_list: 'Lijst',
      brochure_prices: 'Productbrochures',
      brochure_prices_new: 'Nieuw',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'Lijst',
      main_box: 'Bewerken',
      main_boxes_new: 'Nieuw',
      'hello-customer-survey': 'Hello Customer Survey',
      surveys_list: 'Surveys List',
      survey_new: 'Add New Survey',
      survey: 'Survey',
      brochure_file_list: 'Gedetailleerde lijst',
      brochure_price: 'Lijst',
      category_externals_list: 'Externe categorieën',
    },
    orders: {
      view: 'Bestelling',
      new: 'Nieuwe bestelling',
      list: 'Bestellingen',
      edit: 'Bestelling bewerken',
      number: 'Bestelnummer',
      date: 'Bestel datum',
      state: 'Bestel status',
      status: {
        created: 'Gemaakt',
        confirm_order: 'Bestelling bevestigen',
        ready_for_shipment: 'Klaar voor verzending',
        rejected: 'Afgewezen',
        processed: 'Verwerkt',
        shipped: 'Verzonden',
        returned: 'Geretourneerd',
        finished: 'Afgerond',
      },
    },
    salespromotions: {
      view: 'Verkooppromotie',
      list: 'Verkooppromoties',
      premium: 'DRIVEOURBEST',
      guarantee: 'TYREPROTECT',
      bonusCampaign: 'REIFENHITS',
      advantage: 'VORTEILSAKTION',
    },
    countries: {
      de: 'Duitsland',
      at: 'Oostenrijk',
      ch: 'Zwitserland',
      nl: 'Nederland',
    },
    messages: {
      list: 'Berichten',
      new: 'Nieuw bericht',
      edit: 'Bericht bewerken',
      title: 'Titel',
      content: 'Inhoud',
      empty: 'Geen berichten',
      direct: 'Direct bericht',
      toDealers: 'Aan dealers',
      sendEmail: 'E-mail verzenden',
      selectMessage: 'Selecteer een bericht',
      selectWholesalers: 'Groothandelaars selecteren',
      directWholesaler: 'Van groothandel tot dealer',
      saveAndSend: 'Opslaan en verzenden',
      users: 'Selecteer gebruikers (min. 3 tekens)',
      sent: 'Verstuurd',
      externalTrainingPoints: 'Externe Training / Investeringspunten',
      externalTrainingBank: 'Eigenaar van externe training/beleggingsrekening',
      externalTrainingIBAN: 'Externe Opleiding / Investering IBAN',
      onlineTrainingTitle: 'Producttraining - Titel',
      onlineTrainingPoints: 'Onlinetraining - Punten',
      hunterTitle: 'Jager - Titel',
      hunterPoints: 'Jager - Punten',
      hunterPrice: 'Jager - Prijs',
      bTrainingTitle: 'BS-training - Titel',
      bTrainingPoints: 'BS v - Punten',
      pointsProfileComplete: 'Punten - Profiel voltooid',
      surveyUrl: 'Enquête-URL',
      surveyTitle: 'Enquête - Titel',
      orderProducts: 'Producten in de bestelling',
    },
    messages_alert: {
      title: 'Verwijder bericht',
      desc: 'Wilt u het bericht echt verwijderen?',
    },
    groups_alert: {
      title: 'Groep verwijderen',
      desc: 'Wil je de groep echt verwijderen?',
    },
    vendors_alert: {
      title: 'Fabrikant verwijderen',
      desc: 'Wilt u de fabrikant echt verwijderen?',
    },
    suppliers_alert: {
      title: 'Leverancier verwijderen',
      desc: 'Wilt u de leverancier echt verwijderen?',
    },
    threads: {
      title: 'Draden',
      choose: 'Kies een bericht',
      order: 'Kies draad voor bestellingen',
      salespromotions: 'Kies een draad voor verkooppromoties',
      externalTraining: 'Kies draad voor externe trainingen',
      investment: 'Kies draad voor investering',
      onlineTraining: 'Kies een draad voor producttraining',
      bTraining: 'Kies draad voor BS Training',
      profileComplete: 'Kies draad voor Profiel compleet',
      activeUser: 'Actief gebruikersbericht',
      surveys: 'Bericht voor enquête',
      survey_thankyou: 'Bedanktpagina van enquête',
    },
    userGroup: {
      name: 'Groepsnaam',
      gka: 'GKA',
    },
    categories: 'Categorieën',
    count_users: 'Tel gebruikers',
    count_perms: 'Machtigingen tellen',
    count_products: 'Aantal producten',
    count_options: 'Aantal opties',
    product: 'Product',
    attribute_new: 'Nieuw kenmerk',
    product_new: 'Nieuw enkelvoudig product',
    product_new_variants: 'Nieuw product met varianten',
    training_new: 'Nieuwe training',
    btraining_new: 'Nieuwe BS-training',
    category_new: 'Nieuwe categorie toevoegen',
    category_title: 'Naam categorie',
    category_desc: 'Beschrijving categorie',
    category_image: 'Categorie-afbeelding',
    category_link: 'Categoriekoppeling',
    category_alert: {
      title: 'Categorie verwijderen',
      desc: 'Weet u zeker dat u de categorie wilt verwijderen?',
    },
    product_alert: {
      title: 'Categorie verwijderen',
      desc: 'Weet u zeker dat u de categorie wilt verwijderen?',
    },
    survey_alert: {
      title: 'Survey verwijderen',
      desc: 'Weet u zeker dat u de survey wilt verwijderen?',
    },
    survey_alert_activated: {
      title: 'Enquête deactiveren',
      desc: 'Weet u zeker dat u de enquête wilt uitschakelen? Deze kan worden gebruikt in een slider of in een teaser box.',
    },
    survey_alert_message: {
      title: 'Bericht verzenden',
      desc: 'De enquête is gewijzigd. Wilt u dat er berichten worden verzonden naar alle gebruikers die tot de geselecteerde groepen behoren?',
    },
    cancel: 'Annuleren',
    confirm: 'Bevestigen',
    training_edit: 'Training bewerken',
    options_new: 'Nieuwe optie',
    options_edit: 'Bewerk optie',
    subcategory: 'Subcategorie',
    importStarted: 'Het importeren is gestart',
    connection: 'Verbinding',
    variants: 'Varianten',
    add_variant: 'Variant toevoegen',
    vendors: 'Verkoper',
    suppliers: 'Verkoper',
    training: 'Opleiding',
    commands: {
      ipc: 'IPC-importeur',
      users: 'Importeur van gebruikers',
      exports: 'Exports',
    },
    userImport: {
      label: 'Alleen XLSX-type',
    },
    questions: 'Vragen',
    correctAnswer: 'Goed antwoord',
    validation: {
      required: 'Veld is verplicht',
    },
    edit_question: 'Vragen bewerken',
    edit_btraining_Detail: 'Bewerk BS-trainingsdetails',
    video_url: 'Video URL',
    save_new_question: 'Nieuwe vraag opslaan',
    reward: 'Beloningsafbeelding - niet vereist',
    poster: 'Posterafbeelding',
    season: 'Seizoen',
    answers: 'Antwoorden',
    training_details: 'Trainingsdetails',
    extras: 'Extra informatie',
    duration: 'Duur',
    price: 'Prijs',
    min_participant: 'Minimale deelnemers',
    max_participant: 'Maximale deelnemers',
    choose_trainingType: 'Kies trainingstype',
    update: 'Update',
    cost_type: 'Kostentype',
    save_detail: 'Details opslaan',
    slider_new: 'Nieuwe schuifregelaar',
    sliders: 'Schuifregelaars',
    groups: 'Groep',
    slider: 'Schuifregelaar bewerken',
    choose_showHideText: 'Toon/verberg tekst',
    text_page_new: 'Nieuwe tekstpagina',
    text_pages: "Tekstpagina's",
    text_page: 'Tekstpagina',
    featured: {
      title: 'Uitgelichte producten',
      bestseller: 'Meest verkocht',
      seasonal: 'Seizoensgebonden',
      affordable: 'Ontdek',
      autoBestsellers: 'Toon automatisch berekende bestsellers',
      affordableOffsetLabel: 'Maximale prijscompensatie',
      affordableLimitLabel: 'Weergaveproductenlimiet',
    },
    seasons: {
      all: 'All season',
      summer: 'Zomer',
      winter: 'Winter',
      general: 'Algemeen',
    },
    addAddress: 'Voeg adres toe',
    administration: 'Administratie',
    password: 'Wachtwoord',
    toastMessages: {
      success: 'De operatie is met succes uitgevoerd',
      error: 'Onverwachte fout. Probeer het later opnieuw.',
      invalid: 'Ongeldige waarden in formulier',
      done: 'Klaar!',
      requestProcessed:
        'Het verzoek wordt verwerkt. Een ogenblik alstublieft...',
      noQuestions: 'De training moet minimaal 1 vraag bevatten',
      pointsAdded: 'Boeking gemaakt',
      selectUser: 'Selecteer gebruiker uit de lijst',
      selectOneField: 'Selecteer één veld',
      pointRemoveSoon: 'Punten worden binnenkort verwijderd',
    },
    stats: {
      users_list: 'Aantal gebruikers',
      external_trainings_request: 'Externe trainingen',
      product_trainings_request: 'Producttrainingen',
      bridgestone_courses_request: 'BS-opleiding',
      hunter_request: 'Hunter',
      investments_request: 'Investeringssteun',
      orders_list: 'Bestellijst',
      history_list: 'Inloggeschiedenis',
      salespromotions_list: 'Verkooppromoties',
      complaints_list: 'Klachten',
      points_list: 'Puntenlijst',
      kickback_list: 'Bonus',
      history_hour: 'Login hours',
      tyre_list: 'Banden',
    },
    product_trainings_request: 'Aanvragen voor producttrainingen',
    bridgestone_courses_request: 'BS-trainingsaanvragen',
    attempt_number: 'Poging nummer',
    thirdPartyInvoice: 'Factuur van derden',
    userInvoice: 'Gebruikersfactuur',
    bankAccountOwner: 'bank Account eigenaar',
    open: 'Open',
    url: 'URL',
    url_text: 'URL-tekst',
    choose_posterSide: 'Afbeelding kant: ',
    left: 'Links',
    right: 'Rechts',
    disclaimer: 'Vrijwaring',
    supply: {
      history: 'Leveringsgeschiedenis',
      quantity: 'Hoeveelheid',
    },
    copyAsMain: 'Kopieer als hoofdteaser',
    news_feed_slider_list: 'Lijst met schuifregelaar voor nieuwsfeeds',
    usersPage: {
      boostersToApprove: 'Booster om goed te keuren',
      lastLogin: 'Laatste aanmelding:',
      customerNumbers: 'Klantnummer(s)',
      branches: 'Vestiging(en)',
      balance: 'Rekeningsaldo',
      orders: 'Bestellingen',
      groups: 'Netwerk',
      notes: 'Opmerkingen',
      salesStaff: 'Verkooppersoneel',
      parentBranch: 'Moedertak',
      childBranch: 'Ondertak',
      addWholesaler: 'Groothandelaar toevoegen',
      addBranch: 'Filiaal toevoegen',
      tooltip:
        'Om een filiaal toe te voegen, moet u het klantnummer van het bovenliggende filiaal opslaan',
      tooltipRmCnLocal: 'Lokaal klantnummer verwijderen',
      tooltipRmCnDB: 'Klantnummer verwijderen voor database',
      statement: 'Status',
      bank: {
        date: 'Datum',
        supplySourceReason: 'Leveringsbron / Reden',
        customerNumber: 'Klantnr.',
        article: 'Artikel',
        brand: 'Merk',
        product: 'Product',
        type: 'Type',
        inch: 'Omschrijving',
        season: 'Seizoen',
        amount: 'Hoeveelheid',
        otherActions: 'Activiteit',
        totalPoints: 'Punten totaal',
        loadMore: 'Laad nog 20 records',
        seasons: {
          all: 'All season',
          summer: 'Zomer',
          winter: 'Winter',
          general: 'Algemeen',
        },
        advanced: 'Geavanceerd',
        deleteUser: 'Verwijder gebruiker',
      },
      advanced: 'Geavanceerd',
      deleteUser: 'Verwijder gebruiker',
      deleteUserConfirm: 'Weet u zeker dat u de gebruiker wilt verwijderen?',
      selectRole: 'Selecteer Rol',
      selectWholesaler: 'Selecteer Groothandelaar',
    },
    recoverUser: 'Gebruiker ophalen',
    pieces_per_set: 'Verpakkingseenheden',
    new: 'Nieuw',
    all: 'Alle',
    showAddress: 'Adres tonen',
    showGroup: 'Groep tonen',
    showPoints: 'Toon punten',
    showKickbacks: 'Toon Bonus',
    external_sales: 'Externe verkoop',
    login_from: 'Laatste login (vanaf)',
    login_to: 'Laatste login (tot)',
    check: 'Rekening',
    reject: 'Afwijzen',
    reason: 'Reden',
    active_by: 'Geactiveerd door',
    sharePassword: 'Deel dit wachtwoord met de nieuwe beheerder',
    activeBtn: 'Nu actief',
    newUserVerify: 'Verificatie van de nieuwe registratie',
    complaintForm: {
      sections: {
        general: {
          title: 'over het algemeen',
          ordinance: 'Verordening van de dealer',
          generalEmail: 'jouw e-mailadres',
          country: 'Land',
          pickupLocation: 'Ophaalplaats',
        },
        regulator: {
          title: 'adres bandendealer - toezichthouder',
        },
        vehicleDetails: {
          title: 'Voertuig details',
          vehicleTyres: 'voertuig/banden',
          vehicleManufacturer: 'Voertuigfabrikant',
          vehicleModelType:
            'Voertuigmodel en typeaanduiding volgens goedkeuring',
          firstRegistration: 'Eerste registratie',
          enginePower: 'Motorvermogen (KW bijv. 150)',
          originalEquipment: 'originele uitrusting',
          tireMilageHours: 'Kilometerstand (km) / bedrijfsuren',
          milageOrHours: 'km of uren (bijvoorbeeld 2000)',
        },
        tyre: {
          tirePosition: 'Positie van de banden',
          tireSizeExample: 'Bandenmaat (bijv. 215/60/16)',
          speedLoadIndex: 'Snelheid / belastingsindex',
          profileDesignation: 'Profielaanduiding incl. Specificatie',
          dot: 'DOT',
          truckSerialNo: 'serienummer. (alleen voor vrachtwagenbanden)',
          complaintReason: 'Klacht redenen',
          isTyreReplaced: 'Zijn de banden al vervangen?',
          ifYesTyre: 'Zo ja, merk reservebanden',
          clerkName: 'Naam griffier',
          complaintReasonText: 'Klacht tekst',
        },
      },
    },
    yesText: 'Ja',
    noText: 'Nee',
    tyre: 'Band',
    buttons: {
      close: 'Dichtbij',
      placeOrder: 'Bindende bestelling',
      download: 'Downloaden',
      approve: 'Goedkeuren',
      deleteOrder: 'Bestelling verwijderen',
      archive: 'Archief',
      acceptAll: 'Accepteer alles',
      advanceSearch: 'Geavanceerd zoeken',
      addMenu: 'Menu toevoegen',
      setMaintenance: 'Onderhoud instellen',
      hideMaintenance: 'Onderhoud verbergen',
      deactivate: 'Deactiveren',
      importSelected: 'Geselecteerd importeren',
      clearFilters: 'Filter resetten',
      toSearch: 'Search',
      delete: 'Delete',
      importArticleNumbers: 'Importeer deze items',
      filterStatusProducts: 'Filter op status',
    },
    others: 'Anderen',
    archive_training: 'Wil je deze training echt archiveren?',
    delete_menu: 'Do you wanna delete menu item?',
    no_entries: 'Geen toegang',
    external_url: 'Externe URL',
    incomplete_address: 'Adres is onvolledig. Gelieve dit na te kijken',
    request_from: 'Aanvraag van',
    let_see_points: 'Laat de gebruiker marketingpunten zien',
    let_see_kickback: 'Laat de gebruiker de bonus zien',
    major: 'Groep',
    media_category: 'Mediacategorie',
    dragHere: 'Zet de bestanden hier neer...',
    addEmbbeded: 'Voeg ingesloten code toe',
    canReceivePoints: 'Kan punten ontvangen',
    canUsePoints: 'Kan punten gebruiken',
    wholesaler_management: {
      modal: {
        description:
          'Welke gegevens kan de groothandel in dealergegevens zien?',
      },
    },
    personalData: 'Persoonsgegevens',
    operatingData: 'Belangrijkste operationele cijfers',
    orderDelivery: 'Bedrijfsgegevens',
    diffDeliveryAddres: 'Afleveradres toevoegen',
    salesRepresentative: 'Sales Representative',
    status_iban: 'Status IBAN',
    iban: 'IBAN',
    show_export_user_iban: 'Users Dealer Iban',
    do_match: 'Change to Match',
    iban_status: {
      nameMatchResult: {
        MATCH:
          'when the provided name matches the value of the account holder name held by the source',
        CLOSE_MATCH:
          '<b>when the provided name closely resembles the value of the account holder name held by the source</b>',
        NO_MATCH:
          '<b>when the provided name does not match the value of the account holder name held by the source</b>',
        COULD_NOT_MATCH:
          '<b>when the provided name could not be matched against the source data</b>',
        NAME_TOO_SHORT:
          '<b>when the provided name is too short to perform a match against the value of the account holder name held by the source</b>',
      },
      dataUsedForMatching: {
        VERIFIED: 'Verified is the data that exists at the beneficiary bank',
        DERIVED: '<b>Derived is data based on historical transactions</b>',
      },
      accountNumberValidation: {
        VALID:
          'An account that conforms to the standards, e.g. a valid Mod97 calculation for an IBAN',
        NOT_VALID:
          '<b>account is an account that does not conform to the standards</b>',
      },
      paymentPreValidation: {
        PASS: 'is returned when the account identification was successfully validated to an account that can receive funds',
        WILL_FAIL: '<b>is returned if the payment will definitely fail</b>',
        WARNING:
          '<b>is returned in case the account identification was not successfully validated to an account that can receive funds, however, the responding bank is unable to provide a definitive answer</b>',
      },
      status: {
        ACTIVE: 'Account is a valid account and supported for checks',
        INACTIVE:
          '<b>Account is a valid account marked as inactive by the account holding bank, by a third party or by SurePay if SurePay has reason to believe that the account should be marked as inactive</b>',
        NOT_SUPPORTED:
          '<b>Account status stands for an account that is valid but is not supported to perform any checks</b>',
        NOT_FOUND:
          '<b>Account status stands for an account that is valid but could not be found in any of the connected data sources</b>',
        UNKNOWN:
          '<b>Account status is for an account that is either found as part of DERIVED data or a NOT_VALID account</b>',
      },
    },
    current_tyres_amount: 'Banden hoeveelheid',
    current_points: 'Puntensaldo',
    current_kickbacks: 'Bonus',
    change_role_info:
      'Als u een rol in het <b>Bridgestone PLUS-System</b> wijzigt, worden verbindingen verwijderd die aan een bepaalde rol zijn gekoppeld. Zo worden bijvoorbeeld punten, klantnummers, verbindingen tussen een Bridgestone-agent en een dealer, bestellingen, enz. verwijderd. Omdat er geen weg terug is?',
    firstRegistration: 'Eerste registratie',
    mediaCategories: {
      products: 'Produkte',
      brand_and_solutions: 'Marke & Lösungen',
      season_and_promotions: 'Saison & Aktionen',
      format: 'Format',
      cars_and_vans: 'Pkw & Transporter',
      truck_and_buses: 'Lkw & Bus',
      motorcycle: 'Motorrad',
      agriculture: 'Landwirtschaft',
      construction_machinery: 'Baumaschinen',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      summer: 'Sommer',
      winter: 'Winter',
      all_season: 'All Season',
      promotions: 'Aktionen',
      video_in_store: 'Video (Instore)',
      video_online: 'Video (Online)',
      banner_website: 'Banner (Webseite)',
      banner_social_media: 'Banner (Social Media)',
      logos: 'Logos',
      b_drive: 'B-drive',
      media_library: 'Media library',
      media_packages: 'Media packages',
    },
    fileType: {
      label: 'File type',
      video: 'Video',
      image: 'Image',
      embeded: 'Embeded',
      pdf: 'PDF',
    },
    deleteMediaModal: {
      title: 'Delete media collection',
      description:
        "Do you confirm delete file's collection? It will delete all connect files.",
    },
    deleteMediaFileModal: {
      title: 'Delete file from collection',
      description: 'Do you want to you delete file from this collection?',
    },
    deleteBrochureFileModal: {
      title: 'Gegevens uit verzameling verwijderen',
      description: 'Wilt u het bestand uit deze verzameling verwijderen?',
    },
    languages: {
      de: 'Duits',
      ch: 'Duits',
      fr: 'Frans',
      en: 'Engels',
      nl: 'Nederlands',
      it: 'Italiaans',
    },
    colleague: 'medewerker',
    tradeInDate: 'Inruildatum',
    vouchers: {
      points: 'Aantal punten per voucher',
      amount: 'Aantal vouchers',
    },
    selectText: 'Selecteer',
    brochureCategories: {
      products: 'Producten',
      brand_and_solutions: 'Merk',
      season_and_promotions: 'Seizoen & Promoties',
      format: 'Formaat',
      cars_and_vans: 'Car/bestelwagens',
      truck_and_buses: 'Vrachtwagens en bussen',
      motorcycle: 'Motor',
      agriculture: 'Landbouw',
      construction_machinery: 'Grondverzet',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      material: 'Materialen',
      brochures: 'Brochures',
      price_list: 'Prijslijst',
      materials: 'Materialen',
      technology: 'Technology',
      oe: 'OE',
      summer: 'Zomer',
      allSeason: 'All season',
      winter: 'Winter',
      trucks: 'Trucks',
      season: 'Seizoen',
    },
    survey: {
      new: 'Nieuwe enquête',
      edit: 'Enquête bewerken',
      name: 'Naam',
      title: 'Titel',
      description: 'Beschrijving',
      url: 'Hallo Customer, deel URL',
      return_url: 'Doorstuurlink (te gebruiken in Hallo Customer enquête)',
      user_email: 'E-mail',
      sales_contact_bs: 'Sales contact BS',
      quantity_tyres: 'Quantity of tyres bought',
      user_name_of_cp: 'Name of CP',
      user_pos_company_name: 'Level 3: POS Company Name',
      user_gka: 'GKA',
      user_zip_code: 'Postcode',
      user_city: 'City',
      customer_number_bridgestone: 'CN Bridgestone Sold to',
      customer_number_tyrenet: 'CN Distributor 1: Tyrenet',
      customer_number_tyrenet2: 'CN Distributor 1: Tyrenet2',
      customer_number_isb: 'CN Distributor 2: ISB',
      customer_number_vdb: 'CN Distributor 2: VDB',
      customer_number_banden_express: 'CN Distributor 2: BandenExpress',
      customer_number_rbi: 'CN Distributor 2: RBI',
      customer_number_wheeltyre: 'CN Distributor 2: Wheeltyre',
      has_rewards: 'Beloning voor deelname',
      user_information:
        'Metagegevens die naar hallo klant verzonden moeten worden',
      points: 'points',
      surveys_activated: 'Geactiveerde enquêtes',
      select_surveys_activated: 'Selecteer één enquête',
    },
    deleteMainBox: {
      title: 'Hoofdvak verwijderen',
      description: 'Weet u zeker dat u dit item wilt verwijderen?',
    },
    activeCsNumberModal: {
      title: 'Activering van het klantnummer',
      description: 'Wilt u het klantnummer {customer_number} goedkeuren?',
      withCalculation: 'ook activeren voor puntberekening',
      deleteExisting:
        'Bovendien wil ik alle eerdere punten met dit klantnummer verwijderen!',
    },
    inactiveCsNrModal: {
      title: 'Inactief klantnummer',
      description:
        'Hiermee wordt het klantnummer {customer_number} uitgesloten van de puntberekening.',
    },
    categoryExternal: {
      button_save: 'Redden',
      action_external: 'Selecteer de categorieën',
    },
  },
}
