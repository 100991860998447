import ApiService from './api.service'

const ShopService = {
  async loadShopLimitList() {
    try {
      const response = await ApiService.get('shop-limit-list')
      if (response.status = 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },
  async newLimit(data) {
    try {
      return await ApiService.post('admin/shop/limit', data)
    } catch (error) {
      return error.response
    }
  },
  async deleteShopLimit(id) {
    try {
        return await ApiService.delete(`admin/shop/limit/delete/${id}`)
      } catch (error) {
        return error.response
      }
  }
}

export { ShopService }
