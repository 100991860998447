const products = [
  {
    path: 'categories',
    name: 'categories',
    icon: 'cil-spreadsheet',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'en', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'categories_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/category/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
      {
        path: 'new',
        name: 'category_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/category/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
      {
        path: ':id',
        name: 'category',
        components: {
          content: () => import('@/views/pages/category/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
      {
        path: ':id/products',
        name: 'product_category',
        components: {
          content: () => import('@/views/pages/product/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: 'externals',
        name: 'category_externals_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/category_external/List.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'ch'],
        },
      },
    ],
  },

  {
    path: 'orders',
    name: 'orders',
    icon: 'cil-list-high-priority',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'orders_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/orders/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: 'new',
        name: 'orders_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/orders/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
      {
        path: ':id',
        name: 'orders_view',
        components: {
          content: () => import('@/views/pages/orders/View.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
    ],
  },

  {
    path: 'products',
    name: 'products',
    icon: 'cib-product-hunt',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'products_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/product/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: ':id',
        name: 'product',
        components: {
          content: () => import('@/views/pages/product/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: ':id/with-variants',
        name: 'product_variants',
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          variants: true,
          requiredLang: ['de', 'ch', 'nl'],
        },
        components: {
          content: () => import('@/views/pages/product/Edit.vue'),
        },
      },

      {
        path: 'new',
        name: 'product_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/product/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },

      {
        path: 'new/with-variants',
        name: 'product_new_variants',
        showInMenu: true,
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
          variants: true,
        },
        components: {
          content: () => import('@/views/pages/product/New.vue'),
        },
      },
    ],
  },

  {
    path: 'attributes',
    name: 'attributes',
    icon: 'cib-creative-commons-remix',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'attributes_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/attribute/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: ':id',
        name: 'attribute_edit',
        components: {
          content: () => import('@/views/pages/attribute/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },

      {
        path: 'new',
        name: 'attribute_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/attribute/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
    ],
  },

  {
    path: 'options',
    name: 'options',
    icon: 'cil-options',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'en', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'options_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/options/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
      {
        path: 'new',
        name: 'options_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/options/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },

      {
        path: ':id',
        name: 'options_edit',
        components: {
          content: () => import('@/views/pages/options/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
    ],
  },

  {
    path: 'vendors',
    name: 'vendors',
    icon: 'cil-factory',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'vendors_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/vendor/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: ':id',
        name: 'vendor_edit',
        components: {
          content: () => import('@/views/pages/vendor/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },

      {
        path: 'new',
        name: 'vendor_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/vendor/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
    ],
  },

  {
    path: 'suppliers',
    name: 'suppliers',
    icon: 'cib-brand-ai',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'suppliers_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/suppliers/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: ':id',
        name: 'supplier_edit',
        components: {
          content: () => import('@/views/pages/suppliers/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },

      {
        path: 'new',
        name: 'supplier_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/suppliers/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
    ],
  },

  {
    path: 'hunters',
    name: 'hunters',
    icon: 'cib-creative-commons-remix',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'ch'],
    },
    children: [
      {
        path: '',
        name: 'hunter_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/hunter/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
      {
        path: ':id',
        name: 'hunter_edit',
        components: {
          content: () => import('@/views/pages/hunter/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },

      {
        path: 'new',
        name: 'hunter_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/hunter/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
    ],
  },

  {
    path: 'featured',
    name: 'featured',
    icon: 'cil-star',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/featured_products/Index.vue'),
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
  },
  {
    path: 'imported',
    name: 'imported_products',
    icon: 'cil-star',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/import/Index.vue'),
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
  },

  {
    path: 'vouchers',
    name: 'vouchers',
    icon: 'cil-spreadsheet',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['nl'],
    },
    children: [
      {
        path: '',
        name: 'vouchers_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/vouchers/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['nl'],
        },
      },
      {
        path: 'new',
        name: 'vouchers_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/vouchers/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['nl'],
        },
      },
    ],
  },
]

export default products
