export default {
  message: {
    display_points: 'Afficher les points marketing des utilisateurs',
    display_kickbacks:
      "Afficher les frais de commission occulte à l'utilisateur",
    exportDataTitle: 'Télécharger les listes sous forme de fichier Excel',
    mobile_phone: 'Numéro de téléphone portable',
    commitment_letter_accept: "Lettre d'engagement acceptée",
    iban_number: 'Numéro IBAN',
    without_supplier: 'Sans fournisseur',
    price_in_euro: 'Prix de vente',
    is_default: 'défaut',
    attribute_option: "option d'attribut",
    image: 'Image',
    show_stats_user: 'Statistiques de la boutique – UTILISATEURS',
    is_manual_order: "Il s'agit d'une commande manuelle",
    not_available: "Actuellement non disponible à l'époque",
    supplier_id: 'ID du fournisseur',
    show_empty: 'Afficher si inventaire = 0',
    vendor_id: 'ID du fabricant',
    archive_at: 'Archive',
    exported_bridgestone: 'Exportations Bridgestone',
    exported_engelbert: 'ExportationsEngelbert Strauss',
    exported_prime: 'Exportations PremiumDirect',
    exported_other: "D'autres exportés",
    product_variant_id: 'ID de variante de produit',
    cancelled_at: 'Annulé à',
    shipped_at: "L'expédition a lieu le",
    product_id: 'Identifiant du produit',
    quantity: 'Foule',
    qty: 'Menzé',
    action_text: "Texte de l'URL",
    action_url: 'URL',
    token: 'UUID',
    marketing_option: 'Possibilité de commercialisation',
    parent_id: 'ID parent',
    approval_id: "ID d'approbation",
    wholesaler_id: 'Identifiant du grossiste',
    cart_id: 'Identifiant du panier',
    order_date: 'Date de commande',
    order_number: 'Numéro de commande',
    accept_date: "Date d'acceptation",
    wholesaler_dealer_id: 'Identifiant du grossiste',
    dealer_id: 'identifiant du revendeur',
    is_partial_delivery: 'Est-ce une livraison partielle',
    order_completed: 'Commande terminée',
    order_cancelled: 'commande annulée',
    date_delivery: 'date de livraison',
    published: 'Publier',
    properties: 'Caractéristiques',
    group: 'Nom de groupe',
    gka: 'GKA',
    publish_at: 'Publier',
    video: 'URL de la vidéo',
    training_id: 'ID de formation',
    correct: "C'est juste?",
    question_id: 'Numéro de question',
    body: 'Contenu',
    size: 'Taille du texte',
    platform: 'système opérateur',
    is_added: 'Boosters actifs',
    numberOfSetsOfSeasonalTiresBudget: 'stockage par an',
    distributionWinter: 'hiver',
    distributionSummer: 'été',
    overEighteenInch: 'Dont ≥ 18 pouces (en %)',
    tireSalesNumber: 'Ventes de pneus par an env.',
    job_position_id: "Poste dans l'entreprise",
    distributionAll: 'Toutes saisies',
    budgetPremium: 'Prime',
    postal_code: 'Code Postal',
    legalForm: 'forme juridique',
    budgetMid: 'Milieu',
    budgetLow: 'Budget',
    group_id: 'Identifiant du groupe',
    website: 'site web',
    customer_number: 'Numéro de client',
    user_id: "ID de l'utilisateur",
    browser: 'navigateur Internet',
    ip: 'adresse IP',
    email_verified_at: 'E-mail confirmé à',
    last_login: 'Dernière connexion',
    deleted_at: 'supprimé le',
    is_active: "c'est actif?",
    old: 'Vieux',
    selectBooster: 'Sélectionnez le booster',
    activeBooster: 'Booster actif',
    activeBoosterText:
      "Voulez-vous activer un booster actif pour l'utilisateur ?",
    activeBoostersOne:
      'Allez-vous activer ce booster pour chaque utilisateur ?',
    sum: 'total',
    boosterOnTop: 'Points Booster (supplémentaires)',
    relevantBoosterPoints: 'Points pertinents pour les boosters',
    totalBefore: 'Total de points avant',
    standardPoints: 'Points standards',
    boosterPoints: 'Points de rappel',
    allPoints: 'Tous les points',
    pointsByUser: 'Points de téléchargement par utilisateur',
    pointsByGroup: 'Points de téléchargement par groupe',
    loginAs: "Connectez-vous en tant qu'utilisateur",
    positionX: 'Poste X',
    positionY: 'Poste y',
    textSize: 'Taille du texte',
    text: 'Texte',
    publish: 'Publier',
    certificate: 'certificat',
    event: 'événement',
    remove_cs_number:
      'Êtes-vous sûr de vouloir supprimer le numéro de client ?',
    remove_order: 'Êtes-vous sûr de vouloir supprimer la commande ?',
    is_internal: 'Est-ce un utilisateur interne ?',
    selectAddressBtn: "choisir l'adresse",
    currentBasket: 'Panier actuel',
    loginEmail: 'Inscription',
    open_new_tab: 'Ouvrir dans un nouvel onglet?',
    file: 'Déposer',
    company_name: 'entreprise',
    partner_name: 'Personne de contact',
    dealer: 'Marchand',
    boosterImport: {
      label: 'Fichier CSV.',
    },
    created_at: 'Créé sur',
    full_text_search: 'Recherche en texte intégral',
    logs: 'Journaux',
    activity: 'activité',
    booster_create: 'Créer des boosters',
    booster_wholesaler: 'Grossiste',
    booster_products: 'Noms des produits',
    booster_customers: 'Numéros de clients',
    booster_saison: 'Saison',
    booster_name: 'Nom de famille',
    booster_factor: 'facteur',
    booster_from: "temps d'exécution de",
    booster_to: "Durée jusqu'à",
    booster_tires: 'Dimensions des pneus',
    booster_ipcs: 'CIP',
    select: "Choisissez s'il vous plaît",
    booking: 'billetterie',
    credit: 'Crédit',
    deduction: 'Déduction',
    preview: 'Aperçu',
    subject: 'Concernant',
    customerNumber: 'Numéro de client',
    search: 'Chercher',
    customer_nr_name: 'Numéro/nom du client',
    selectAll: 'tout sélectionner',
    import: 'Importer',
    articleNumber: "numéro d'article",
    inform_user: 'informer les clients',
    leaveComment: 'Laissez un commentaire ici',
    user_new: 'Nouvel utilisateur',
    postalcode: 'Code postal',
    city: 'Emplacement',
    residence: 'Est-ce que la résidence',
    country: 'pays',
    street: 'Rue et numéro de maison',
    salutation: 'Salutation',
    suffix: "Supplément d'adresse",
    emailVerified: 'E-mail vérifié le',
    registerAt: 'Inscrit le',
    phone: 'téléphone',
    optinAt: 'Inscrivez-vous sur',
    fax: 'Fax',
    date: 'Date',
    optinToken: "Jeton d'inscription",
    language: 'Langue',
    company: 'entreprise',
    forename: 'Prénom',
    lastname: 'Nom de famille',
    title: 'titre',
    remove: 'Supprimer',
    actions: 'Actions',
    userData: "Données d'utilisateur",
    profile: 'Profil',
    addresses: 'Adresses',
    address: 'Adresse',
    show0Products: "Afficher lorsque l'inventaire = 0",
    notAvailable: 'Actuellement non disponible pour le moment',
    limitless: 'Illimité',
    isDefault: 'Défaut',
    data: 'Données',
    importText: 'Veuillez sélectionner un fichier à importer:',
    upload: 'Télécharger',
    users: 'utilisateur',
    permission: 'Droite',
    save: 'Sauvegarder',
    downloadOrderStats: 'Télécharger les statistiques',
    downloadProductStats: 'Télécharger les statistiques des produits',
    category: 'catégorie',
    order: 'Commande',
    permissions: 'droite',
    options: 'Possibilités',
    attributes: 'Les attributs',
    add_attribute: 'Ajouter des attributs',
    login: 'se connecter',
    logout: 'Se déconnecter',
    email: 'E-Mail',
    username: 'utilisateur',
    filter: 'Filtre',
    name: 'Nom de famille',
    points: 'Points',
    sku: 'UGS',
    count: 'Nombre',
    action: 'Action',
    active: 'Actif',
    type: 'Taper',
    status: 'condition',
    main: 'Produit principal',
    vendor: 'Fabricant',
    supplier: 'fournisseur',
    variant: 'Variante de produit',
    description: 'Description',
    short_description: 'Brève description',
    upload_text: "Téléchargement d'images de produits",
    product_new: 'Nouveau produit unique',
    product_new_variants: 'Nouvelle variante de produit',
    training_new: 'Nouvelle formation',
    btraining_new: 'Nouvelle formation BS',
    images: 'Des photos',
    roles: 'rouler',
    search_products: 'Recherche de produit',
    search_orders: 'Ordres de Recherche',
    search_type: 'Type de recherche',
    hunter_new: 'Nouveau chasseur',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    copy: 'Copie',
    add_empty_variant: 'Ajouter une variante vide',
    menu: {
      shopLimits: 'Shop limits',
      default_banners: 'Bannières par défaut',
      import_adex: 'importation adex',
      adex: 'Adex',
      logs: 'journaux',
      activities: 'activités',
      booster_setup: 'Configurer des boosters',
      booster_list: 'Liste',
      customer: 'Clients',
      'users-roles': 'Gestion des utilisateurs',
      roles: 'rouler',
      booster: 'amplificatrice',
      cheques: 'Chèques et déductions',
      points: 'Gestion des points',
      users: 'utilisateur',
      users_list: "Liste d'utilisateur",
      user_new: 'Nouvel utilisateur',
      users_deleted: 'Utilisateurs supprimés',
      perms: 'droite',
      'products-categories': 'Boutique de récompenses',
      categories: 'Catégories',
      categories_list: 'Liste des catégories',
      category_new: 'Nouvelle catégorie',
      orders: 'Ordres',
      products: 'Des produits',
      products_list: 'Liste de produits',
      product_new: 'Nouveau produit unique',
      product_new_variants: 'Nouvelle variante de produit',
      attributes: 'Les attributs',
      attributes_list: 'Liste des attributs',
      attribute_new: 'Nouveaux attributs',
      options: 'Possibilités',
      options_list: 'Liste des options',
      options_new: 'Nouvelle option',
      vendors: 'Fabricant',
      vendors_list: 'Liste des fabricants',
      vendor_new: 'Nouveau fabricant',
      suppliers: 'Fournisseurs',
      suppliers_list: 'Liste des fournisseurs',
      supplier_new: 'Nouveau fournisseur',
      sliders: 'Curseur principal du teaser',
      sliders_list: 'Liste',
      slider_new: 'Nouveau curseur de teaser principal',
      featured: 'Produits teasers',
      commands: 'Commandes',
      command_ipc: 'CIB',
      command_users: 'Importer des utilisateurs',
      'trainings-schulungen': 'Formation et éducation',
      trainings: 'La formation en ligne',
      trainings_list: 'Liste',
      training_new: 'Ajouter un nouveau',
      btrainings: 'Formation BS',
      btrainings_list: 'Liste',
      btraining_new: 'Ajouter un nouveau',
      management: 'Gestion de contenu',
      textpages: 'Pages de texte',
      textpages_list: 'Liste',
      text_page_new: 'Ajouter un nouveau',
      settings: 'Responsable des actualités',
      messages: 'Créer des messages',
      messages_list: 'Liste',
      messages_new: 'Ajouter un nouveau',
      threads: 'Configurer des e-mails automatiques',
      requests: 'Demandes des utilisateurs',
      product_trainings_request: 'La formation en ligne',
      bridgestone_courses_request: 'Formation BS',
      external_trainings_request: 'Formation extérieure',
      hunter_request: 'chasseur',
      investments_request: "Soutien à l'investissement",
      news_feed: 'nouvelles',
      news_feed_list: 'Liste',
      news_feed_new: 'Ajouter un nouveau',
      salespromotions: 'Promotion de ventes',
      imported_products: 'Produits importés',
      hunter_list: 'Liste',
      hunters: 'Chasseur',
      hunter_new: 'Nouveau chasseur',
      groups: 'groupes',
      groups_list: 'Liste de groupe',
      group_new: 'Nouveau groupe',
      history: 'Historique de connexion',
      history_list: 'Historique de connexion',
      prices: 'Nouveau Pricat',
      prices_list: 'Liste',
      price_new: 'Nouveau Pricat',
      price_categories: 'Listes de prix',
      price_categories_list: 'Liste',
      price_categories_new: 'Nouvelles listes de prix',
      inactive_users: 'Nouvelles inscriptions<br/>libération',
      customer_numbers_to_approve: 'Nouvelles inscriptions<br/>sans numéro',
      customer_numbers_assistant: 'Assistant de numéros de client',
      complaints: 'Formulaire de réclamation',
      orders_list: 'Liste',
      orders_new: 'Nouvelles commandes',
      points_list: 'Liste des points',
      kickback_list: 'Frais de commission',
      booster_preview: 'Aperçu',
      frontend_menu: 'Menu frontal',
      frontend_menu_list: 'Liste',
      frontend_menu_new: 'Nouveau',
      mediapages: 'Forfaits médias',
      mediapages_list: 'Liste',
      media_page_new: 'Nouveau',
      uberall_request: 'Uberall',
      history_hour: "Horaires d'inscription",
      wholesaler_management: 'Gestion des grossistes',
      mediapages_file_list: 'Liste de fichiers',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'New',
      brochure_prices_list: 'Liste',
      brochure_prices: 'Brochures produits & listes de prix',
      brochure_prices_new: 'Nouveau',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'List',
      main_box: 'Edit',
      main_boxes_new: 'New',
    },
    breadcrumb: {
      customer_numbers_assistant: 'Assistant de numéros de client',
      shopLimits: 'Shop limits',
      default_banners: 'Bannières par défaut',
      import_adex: 'Importation Adex',
      adex: 'Adex',
      activities: 'activités',
      booster_setup: 'Configurer des boosters',
      booster_list: 'Liste',
      customer: 'clients',
      booster: 'Amplificateur',
      cheques: 'Chèques et déductions',
      user_edit: "Modifier l'utilisateur",
      users: 'utilisateur',
      user: 'utilisateur',
      vendors: 'Fabricant',
      vendor_new: 'Nouveau fabricant',
      suppliers: 'Fournisseurs',
      supplier_new: 'nouveau fournisseur',
      home: 'Maison',
      perms: 'droite',
      roles: 'rouler',
      roles_list: 'Liste',
      dashboard: 'Tableau de bord',
      orders: 'Ordres',
      orders_edit: 'Modifiez votre commande',
      orders_view: 'Commande',
      orders_list: 'Liste',
      salespromotions: 'Promotion de ventes',
      salespromotions_list: 'Liste',
      salespromotions_view: 'voir',
      categories: 'Catégories',
      categories_list: 'Liste',
      role: 'rôle',
      category: 'catégorie',
      products: 'Des produits',
      products_list: 'Liste',
      product: 'produit',
      product_category: 'Produits de la catégorie',
      product_new: 'Nouveau produit unique',
      product_new_variants: 'Nouvelle variante de produit',
      product_variants: 'Produit variante',
      attributes: 'Produit variante',
      attributes_list: 'Liste',
      attribute: 'Attribut',
      category_new: 'Nouvelle catégorie',
      attribute_edit: "Modifier l'attribut",
      options_new: 'Nouvelle option',
      options: 'Possibilités',
      options_list: 'Liste',
      options_edit: "Modifier l'option",
      userEdit: "Modifier l'utilisateur",
      users_list: 'Liste',
      users_deleted: 'Utilisateurs supprimés',
      vendor_edit: 'Modifier le fabricant',
      vendors_list: 'Liste',
      supplier_edit: 'Modifier le fournisseur',
      suppliers_list: 'Liste des fournisseurs',
      user_new: 'Nouvel utilisateur',
      trainings: 'Entraînement',
      trainings_list: 'Liste',
      btrainings: 'La formation en ligne',
      btrainings_list: 'Liste',
      btraining: 'La formation en ligne',
      training_new: 'Nouvelle formation',
      btraining_new: 'Nouvelle formation BS',
      training_edit: 'Modifier la formation',
      training: 'entraînement',
      command_ipc: 'CIB',
      sliders: 'Curseur principal du teaser',
      sliders_list: 'Liste',
      slider_new: 'Nouveau curseur de teaser principal',
      slider: 'Modifier le curseur du teaser principal',
      command_users: 'Importer des utilisateurs',
      textpages: 'Pages de texte',
      text_page_new: 'Nouvelle page de texte',
      textpage: 'Pages de texte',
      textpages_list: 'Liste',
      messages: 'Nouvelles',
      messages_list: 'Liste',
      messages_new: 'Nouveau message',
      messages_edit: 'modifier le message',
      threads: 'Concernant',
      featured: 'Produits teasers',
      attribute_new: 'Nouvel attribut',
      product_trainings_request: 'Demandes de formation en ligne',
      bridgestone_courses_request: 'Demandes de formation Bridgestone',
      external_trainings_request: 'Demandes de formations externes',
      hunter_request: 'Chasseur',
      investments_request: "Soutien à l'investissement",
      news_feed: 'nouvelles',
      news_feed_list: 'Liste',
      news_feed_new: "Nouveau fil d'actualité",
      news_feed_edit: "Modifier le fil d'actualité",
      news_feed_slider: 'Nouveau curseur de fil d’actualité',
      imported_products: 'Produits importés',
      hunter_list: 'Liste',
      hunters: 'Chasseur',
      hunter_new: 'Nouveau chasseur',
      hunter_edit: 'Modifier le chasseur',
      groups: 'groupes',
      groups_list: 'Liste de groupe',
      group_new: 'Nouveau groupe',
      group_edit: 'Groupe Modifier',
      history: 'Historique de connexion',
      history_list: 'Historique de connexion',
      history_view: 'Historique de connexion des utilisateurs',
      prices: 'Pricat',
      prices_list: 'Liste',
      price_new: 'nouveau Pricat',
      price: 'Pricat modifier',
      price_categories: 'Liste de prix',
      price_categories_list: 'Liste',
      price_categories_new: 'Nouvelles listes de prix',
      price_category: 'Liste de prix',
      inactive_users: 'Publier les nouvelles inscriptions',
      inactive_users_list: 'Liste',
      customer_numbers_to_approve: 'Nouvelles inscriptions sans numéro',
      complaints: 'Formulaire de réclamation',
      complaints_list: 'Liste',
      complaints_view: 'Plainte',
      orders_new: 'Nouvelles commandes',
      points_list: 'Liste des points',
      kickback_list: 'Frais de commission',
      booster_preview: 'Aperçu',
      frontend_menu: 'Menu frontal',
      frontend_menu_list: 'Liste',
      frontend_menu_new: 'Nouveau',
      mediapages: 'Forfaits médias',
      mediapages_list: 'Liste',
      media_page_new: 'Nouveau',
      mediapage: 'Modifier',
      uberall_request: 'Partout',
      history_hour: "Horaires d'inscription",
      wholesaler_management: 'Gestion des grossistes',
      mediapages_file_list: 'Liste de fichiers',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'New',
      brochure_prices_list: 'Liste',
      brochure_prices: 'Brochures produits & listes de prix',
      brochure_prices_new: 'Nouveau',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'List',
      main_box: 'Edit',
      main_boxes_new: 'New',
    },
    orders: {
      view: 'Commande',
      new: 'nouvel ordre',
      list: 'Ordres',
      edit: 'Modifiez votre commande',
      number: 'Numéro de commande',
      date: 'Date de commande',
      state: 'statut de la commande',
      status: {
        created: 'Créé',
        confirm_order: 'confirmer la commande',
        ready_for_shipment: "Prêt pour l'expédition",
        rejected: 'Rejeté',
        processed: 'Traité',
        shipped: 'Envoyé',
        returned: 'Revenu',
        finished: 'Prêt',
      },
    },
    salespromotions: {
      view: "promotion d'achat",
      list: 'Promotions des ventes',
      premium: 'CONDUIRE NOTRE MEILLEUR',
      guarantee: 'PROTECTION DES PNEUS',
      bonusCampaign: 'COUP DE PNEUS',
      advantage: 'AVANTAGE PROMOTION',
    },
    countries: {
      de: 'Allemagne',
      at: "L'Autriche",
      ch: 'Suisse',
      nl: 'Pays-Bas',
    },
    messages: {
      list: 'Nouvelles',
      new: 'Nouveau message',
      edit: 'modifier le message',
      title: 'titre',
      content: 'Contenu',
      empty: 'Pas de nouvelles',
      direct: 'Message direct',
      toDealers: 'Aux concessionnaires',
      sendEmail: 'Envoyer un e-mail',
      selectWholesalers: 'Sélectionnez un grossiste',
      directWholesaler: 'du grossiste au revendeur',
      saveAndSend: 'Enregistrer et envoyer',
      users: "Sélectionnez l'utilisateur (min. 3 caractères)",
      sent: 'Publié',
      externalTrainingPoints:
        'Points externes de soutien à la formation/à l’investissement',
      externalTrainingBank:
        'Titulaires de comptes externes de formation/soutien à l’investissement',
      externalTrainingIBAN:
        "Formation externe / accompagnement à l'investissement IBAN",
      onlineTrainingTitle: 'Formation en ligne - Titre',
      onlineTrainingPoints: 'Formation en ligne - points',
      hunterTitle: 'Chasseur - titre',
      hunterPoints: 'Chasseur - Points',
      hunterPrice: 'Chasseur - Prix',
      bTrainingTitle: 'Formation Bridgestone - Titel',
      bTrainingPoints: 'Formation BS - Points',
      pointsProfileComplete: 'Points - profil complété',
      orderProducts: 'Produits dans la commande',
    },
    messages_alert: {
      title: 'Supprimer le message',
      desc: 'Voulez-vous vraiment supprimer le message ?',
    },
    groups_alert: {
      title: 'Supprimer le groupe',
      desc: 'Êtes-vous sûr de vouloir supprimer le groupe ?',
    },
    vendors_alert: {
      title: 'Supprimer le fabricant',
      desc: 'Voulez-vous vraiment supprimer le fabricant ?',
    },
    suppliers_alert: {
      title: 'Supprimer le fournisseur',
      desc: 'Voulez-vous vraiment supprimer le fournisseur ?',
    },
    threads: {
      title: 'Concernant',
      choose: 'Veuillez sélectionner un message',
      order: 'Sélectionnez le sujet des commandes',
      salespromotions: 'Sélectionnez le sujet de la promotion des ventes',
      externalTraining: 'Sélectionner le sujet de la formation externe',
      investment: "Objet du soutien à l'investissement",
      onlineTraining: 'Sujet de formation en ligne',
      hunter: 'Sujet pour Hunter',
      bTraining: 'Sujet de formation Bridgestone',
      profileComplete: 'Sujet de remplissage du profil',
      activeUser: 'Message utilisateur actif',
    },
    userGroup: {
      name: 'Nom de groupe',
      gka: 'GKA',
    },
    role: 'rôle',
    user: 'utilisateur',
    vat: "numéro d'identification fiscale",
    categories: 'Catégories',
    count_users: "nombre d'utilisateurs",
    product: 'produit',
    count_perms: 'Nombre de droits',
    count_products: 'Nombre de produits',
    count_options: "Nombre d'options",
    attribute_new: 'Nouvel attribut',
    category_new: 'Nouvelle catégorie',
    category_title: 'Titre de la catégorie',
    category_desc: 'Description de la catégorie',
    category_image: 'Catégorie Image',
    category_link: 'Lien de catégorie',
    category_alert: {
      title: 'Supprimer la catégorie ',
      desc: 'Voulez-vous vraiment supprimer la catégorie ?',
    },
    product_alert: {
      title: 'Supprimer le produit',
      desc: 'Êtes-vous sûr de vouloir supprimer le produit ?',
    },
    cancel: 'Annuler',
    confirm: 'Confirmer',
    options_new: 'Nouvelle option',
    subcategory: 'Sous-catégorie',
    uploadText: 'pour télécharger une photo',
    startImport: "Démarrer l'importation",
    importStarted: "L'importation a commencé",
    connection: 'Connexion',
    variants: 'variantes',
    add_variant: 'Ajouter une variante',
    vendor_new: 'Nouveau fabricant',
    training_edit: 'Modifier la formation',
    vendors: 'Fabricant',
    supplier_new: 'Nouveau fournisseur',
    suppliers: 'Fournisseurs',
    training: 'entraînement',
    commands: {
      ipc: 'Importateur IPC',
      users: 'Importer des utilisateurs',
    },
    userImport: {
      label: 'Type XLSX uniquement',
    },
    questions: 'Des questions',
    correctAnswer: 'Bonne réponse',
    validation: {
      required: 'Champ requis',
    },
    edit_question: 'Modifier les questions',
    edit_btraining_Detail: 'Modifier les détails de la formation Bridgestone',
    video_url: 'URL de la vidéo',
    save_new_question: 'Enregistrer une nouvelle question',
    reward: 'Illustration de la prime - non obligatoire',
    poster: "Image de l'affiche",
    season: 'Saison',
    answers: 'Répondre',
    training_details: 'Détails de la formation',
    extras: 'Informations complémentaires',
    duration: 'Une longueur de temps',
    price: 'Prix',
    min_participant: 'Nombre minimum de participants',
    max_participant: 'Nombre maximum de participants',
    choose_trainingType: 'Sélectionnez le type de formation',
    update: 'Mettre à jour',
    cost_type: 'Type de coût',
    save_detail: 'Enregistrer les détails',
    slider_new: 'Nouveau curseur de teaser principal',
    sliders: 'Curseur principal du teaser',
    groups: 'groupes',
    slider: 'Modifier le curseur du teaser principal',
    choose_showHideText: 'Afficher/masquer le texte',
    text_page_new: 'Nouvelle page de texte',
    text_pages: 'Pages de texte',
    text_page: 'Page de texte',
    featured: {
      title: 'Produits teasers',
      bestseller: 'Plus vendu',
      seasonal: 'Saisonnier',
      affordable: 'Presque là',
      autoBestsellers: 'Afficher les best-sellers calculés automatiquement',
      affordableOffsetLabel: 'Compensation de prix maximale',
      affordableLimitLabel: 'Afficher la limite de produits',
    },
    seasons: {
      all: 'Toutes les saisons',
      summer: 'Été',
      winter: 'hiver',
      general: 'En général',
    },
    addAddress: 'Ajouter une adresse',
    administration: 'Administration',
    password: 'mot de passe',
    toastMessages: {
      success: "L'opération s'est terminée avec succès",
      error: 'Erreur inattendue, veuillez réessayer plus tard',
      invalid: 'Valeurs invalides dans le formulaire',
      done: 'Complété!',
      requestProcessed:
        "La demande est en cours de traitement. Un instant s'il vous plaît...",
      noQuestions: 'La formation doit contenir au moins 1 question',
      pointsAdded: 'Réservation effectuée',
      selectUser: 'Sélectionnez un utilisateur dans la liste',
      selectOneField: 'Sélectionnez un champ',
      pointRemoveSoon: 'Les points seront bientôt supprimés',
      dataLoading:
        'Les données sont en cours de chargement, veuillez patienter',
    },
    stats: {
      users_list: "nombre d'utilisateurs",
      external_trainings_request: 'Formation extérieure',
      product_trainings_request: 'La formation en ligne',
      bridgestone_courses_request: 'Formation Bridgestone',
      hunter_request: 'Chasseur',
      investments_request: "Soutien à l'investissement",
      orders_list: 'Ordres',
      history_list: 'Historique de connexion',
      salespromotions_list: 'Promotion de ventes',
      inactive_users: 'Publier les nouvelles inscriptions',
      complaints_list: 'Formulaire de réclamation',
      points_list: 'Liste des points',
      kickback_list: 'Frais de commission',
      history_hour: "Horaires d'inscription",
      tyre_list: 'Pneus',
    },
    product_trainings_request: 'Demandes de formation en ligne',
    bridgestone_courses_request: 'Demandes de formation Bridgestone',
    attempt_number: "Numéro d'essai",
    thirdPartyInvoice: 'Facture de tiers',
    userInvoice: "Facture de l'utilisateur",
    bankAccountOwner: 'propriétaire du compte',
    open: 'Ouvrir',
    url: 'URL',
    url_text: "Texte de l'URL",
    choose_posterSide: "Page d'images: ",
    left: 'Gauche',
    right: 'Droite',
    disclaimer: 'Clause de non-responsabilité',
    supply: {
      history: 'Historique des approvisionnements',
      quantity: 'Foule',
    },
    copyAsMain: 'Copier comme teaser principal',
    news_feed_slider_list: 'La liste des curseurs du fil d’actualité',
    usersPage: {
      boostersToApprove: 'Booster pour approbation',
      lastLogin: 'Dernière connexion',
      customerNumbers: 'Numéro de client',
      branches: 'branches',
      balance: 'solde bancaire',
      orders: 'Ordres',
      groups: 'groupes',
      notes: 'Remarques',
      salesStaff: 'Ventes externes',
      parentBranch: "l'affaire principal",
      childBranch: 'bifurquer',
      addWholesaler: 'Ajouter un grossiste',
      addBranch: 'Ajouter une branche',
      tooltip:
        'Pour ajouter une succursale, vous devez enregistrer le numéro de client du magasin principal',
      tooltipRmCnLocal: 'Supprimer le numéro de client local',
      tooltipRmCnDB: 'Supprimer le numéro de client de la base de données',
      statement: 'relevé de compte',
      bank: {
        date: 'Date',
        supplySourceReason: 'Origine/raison',
        customerNumber: 'Kdnr.',
        article: 'Article',
        brand: 'marque',
        product: 'produit',
        type: 'Taper',
        inch: 'douane',
        season: 'Saison',
        amount: 'Foule',
        otherActions: 'Autre action ou cause',
        totalPoints: 'Points totaux',
        loadMore: 'Chargez encore 20 chaussons',
        seasons: {
          all: 'Toute la saison',
          summer: 'Avertisseur sonore',
          winter: 'hiver',
          general: 'Générale',
        },
      },
      advanced: 'Avancé',
      deleteUser: "Supprimer l'utilisateur",
      deleteUserConfirm: "Etes-vous sûr de vouloir supprimer l'utilisateur ?",
      selectRole: 'Sélectionnez un rôle',
      selectWholesaler: 'Sélectionnez un grossiste',
    },
    recoverUser: 'Ramener les utilisateurs',
    pieces_per_set: "Unités d'emballage",
    new: 'Nouveau',
    all: 'Tous',
    showAddress: "Afficher l'adresse",
    showGroup: 'Afficher GKA et Bl-Alt',
    showPoints: 'Afficher les scores',
    showKickbacks: 'Afficher les frais de commission',
    external_sales: 'Ventes externes',
    login_from: 'Dernière connexion (à partir de)',
    login_to: "Dernière connexion (jusqu'à)",
    check: 'Vérifier',
    reject: 'Refuser',
    reason: 'Raison',
    active_by: 'Activé par',
    sharePassword: 'Partagez ce mot de passe avec le nouvel administrateur',
    activeBtn: 'Activer maintenant',
    newUserVerify: 'Vérification de la nouvelle inscription',
    complaintForm: {
      sections: {
        general: {
          title: 'en général',
          ordinance: 'Concept de commande du concessionnaire',
          generalEmail: 'Votre adresse e-mail',
          country: 'pays',
          pickupLocation: 'Lieu de ramassage',
        },
        regulator: {
          title: 'adresse du revendeur de pneus - régulateur',
        },
        vehicleDetails: {
          title: 'Informations sur le véhicule',
          vehicleTyres: 'Véhicule/Pneus',
          vehicleManufacturer: 'Constructeur automobile',
          vehicleModelType:
            'Modèle de véhicule et désignation de type selon homologation',
          firstRegistration: 'Inscription initiale',
          enginePower: 'Puissance du moteur (KW par exemple 150)',
          originalEquipment: 'Equipements originels',
          tireMilageHours:
            'Kilométrage des pneus (km) / heures de fonctionnement',
          milageOrHours: 'Km ou heures (par exemple 2000)',
        },
        tyre: {
          tirePosition: 'Position des pneus',
          tireSizeExample: 'Taille des pneus (par exemple 215/60/16)',
          speedLoadIndex: 'Indice de vitesse/charge',
          profileDesignation: 'Désignation du profil avec spécification',
          dot: 'POINT',
          truckSerialNo:
            'Numéro de série. (uniquement pour les pneus de camion)',
          complaintReason: 'Motif de la plainte',
          isTyreReplaced: 'Les pneus ont-ils déjà été remplacés ?',
          ifYesTyre: 'Si oui, marque des pneus de remplacement',
          clerkName: 'Nom du greffier',
          complaintReasonText: 'Texte du motif de la réclamation',
        },
      },
    },
    yesText: 'Oui',
    noText: 'Non',
    tyre: 'Pneus',
    buttons: {
      close: 'Fermer',
      placeOrder: 'Ordonnance ferme',
      download: 'Télécharger',
      approve: 'Pour permettre',
      deleteOrder: 'Supprimer la commande',
      archive: 'archive',
      acceptAll: 'Accepter tout',
      advanceSearch: 'Recherche Avancée',
      addMenu: 'Ajouter un menu',
      setMaintenance: "Définir l'entretien",
      hideMaintenance: "Masquer l'entretien",
      deactivate: 'Désactiver',
      importSelected: 'Importer la sélection',
      clearFilters: 'Filter zurücksetzen',
      toSearch: 'Search',
      delete: 'Delete',
    },
    others: 'Autre',
    archive_training: 'Êtes-vous sûr de vouloir archiver cette formation ?',
    delete_menu: "Souhaitez-vous supprimer l'élément de menu ?",
    no_entries: 'Aucune entrée',
    external_url: 'URL externe',
    incomplete_address: "L'adresse est incomplète. Vérifiez s'il vous plaît",
    request_from: 'demande de',
    let_see_points: "Laissez l'utilisateur voir les points marketing",
    let_see_kickback:
      "Permettre à l'utilisateur de voir les frais de commission occulte",
    major: 'Grep',
    media_category: 'Catégorie "Médias“',
    dragHere: 'Déposez les fichiers ici...',
    addEmbbeded: 'Ajouter du code intégré',
    canReceivePoints: 'Peut obtenir des points',
    canUsePoints: 'Peut utiliser des points',
    wholesaler_management: {
      modal: {
        description:
          'Quelles données le grossiste peut-il voir dans les coordonnées du revendeur ?',
      },
    },
    personalData: 'Données personnelles',
    operatingData: "Données d'exploitation",
    orderDelivery: 'adresse de livraison',
    diffDeliveryAddres: 'ajouter une adresse de livraison',
    current_tyres_amount: 'Montant des pneus',
    current_points: 'Score',
    current_kickbacks: 'Kick-back',
    change_role_info:
      "La modification d'un rôle dans le <b>Bridgestone PLUS-System</b> supprime les connexions associées à un rôle donné, par exemple les points, les numéros de client, les connexions entre un processeur Bridgestone et un revendeur, les commandes, etc. sont supprimés. parce qu'il n'y a pas de retour possible ?",
    firstRegistration: 'Première inscription',
    mediaCategories: {
      products: 'Produkte',
      brand_and_solutions: 'Marke & Lösungen',
      season_and_promotions: 'Saison & Aktionen',
      format: 'Format',
      cars_and_vans: 'Pkw & Transporter',
      truck_and_buses: 'Lkw & Bus',
      motorcycle: 'Motorrad',
      agriculture: 'Landwirtschaft',
      construction_machinery: 'Baumaschinen',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      summer: 'Sommer',
      winter: 'Winter',
      all_season: 'All Season',
      promotions: 'Aktionen',
      video_in_store: 'Video (Instore)',
      video_online: 'Video (Online)',
      banner_website: 'Banner (Webseite)',
      banner_social_media: 'Banner (Social Media)',
      logos: 'Logos',
      b_drive: 'B-drive',
      media_library: 'Media library',
      media_packages: 'Media packages',
    },
    fileType: {
      label: 'File type',
      video: 'Video',
      image: 'Image',
      embeded: 'Embeded',
    },
    deleteMediaModal: {
      title: 'Delete media collection',
      description:
        "Do you confirm delete file's collection? It will delete all connect files.",
    },
    deleteMediaFileModal: {
      title: 'Delete file from collection',
      description: 'Do you want to you delete file from this collection?',
    },
    languages: {
      de: 'Allemand',
      ch: 'Allemand',
      fr: 'Français',
      en: 'Anglais',
      nl: 'Néerlandais',
      it: 'Italien',
    },
    colleague: 'collègue',
    tradeInDate: 'data di permuta',
    vouchers: {
      points: 'Nombre de points par bon',
      amount: 'Nombre de bons',
    },
    selectText: 'Sélectionner',
    brochureCategories: {
      products: 'Produits',
      brand_and_solutions: 'Marque & Solutions',
      season_and_promotions: 'Saison & Promotions',
      format: 'Format',
      cars_and_vans: 'Voitures et utilitaires',
      truck_and_buses: 'Camions et bus',
      motorcycle: 'Motocycle',
      agriculture: 'Agriculture',
      construction_machinery: 'Matériel de construction',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      material: 'Matériaux',
      brochures: 'Brochures',
      price_list: 'Liste de prix',
      materials: 'Matériaux',
    },
    deleteMainBox: {
      title: 'Supprimer la boîte principale',
      description: 'Etes-vous sûr de vouloir supprimer cet élément?',
    },
    activeCsNumberModal: {
      title: 'Activation du numéro client',
      description:
        'Souhaitez-vous approuver le numéro client {customer_number} ?',
      withCalculation: 'activer également pour le calcul des points',
      deleteExisting:
        'De plus, je souhaite supprimer tous les points précédents avec ce numéro client !',
    },
    inactiveCsNrModal: {
      title: 'Numéro client inactif',
      description:
        'Cela exclura le numéro client {customer_number} du calcul des points.',
    },
  },
}
