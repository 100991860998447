import ApiService from './api.service'

const SearchService = {
  async search(search) {
    try {
      return await ApiService.post('admin/users/search', search)
    } catch (e) {
      return e.response
    }
  },
  async searchCheck(search) {
    try {
      return await ApiService.post('admin/users/search/check', search)
    } catch (e) {
      return e.response
    }
  },
  async filter(search) {
    try {
      return await ApiService.post('admin/users/search/filter', search)
    } catch (e) {
      return e.response
    }
  },
}
export { SearchService }
