import ApiService from './api.service'

const ComplaintService = {
  async getComplaints(page) {
    try {
      return await ApiService.get(`complaints?page=${page}`)
    } catch (error) {
      return error
    }
  },

  async getComplaint(id) {
    try {
      return await ApiService.get(`complaints/${id}`)
    } catch (error) {
      return error
    }
  },

  async approveComplaint(id) {
    try {
      return await ApiService.put(`complaints/approve/${id}`)
    } catch (error) {
      return error
    }
  },
}

export { ComplaintService }
