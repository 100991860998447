<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      {{ $t('message.breadcrumb.' + item.name) }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import router from '@/router'

const breadcrumbs = ref()

const getBreadcrumbs = () => {
  return router.currentRoute.value.matched
    .filter((item) => !item.meta.isParent)
    .map((route) => {
      return {
        active: route.name === router.currentRoute.value.name,
        name: route.name,
        path: '/#' + route.path,
      }
    })
}

router.afterEach(() => {
  breadcrumbs.value = getBreadcrumbs()
})

onMounted(() => {
  breadcrumbs.value = getBreadcrumbs()
})
</script>
