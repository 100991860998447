export default {
  message: {
    customerNumbersAssistant: {
      date: {
        title: 'Punkte ab dem Datum X berechnen',
        warning:
          'Mit einem ausgewählten Datum können keine Hauptgeschäftsnummern verschoben werden!',
      },
    },
  },
}
