import { createStore } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { actions } from './actions'

export default createStore({
  state: {
    ...state,
  },
  mutations: {
    ...mutations,
  },

  actions: {
    ...actions,
  },

  getters: {
    ...getters,
  },

  modules: {},
})
