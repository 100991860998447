import ApiService from './api.service'

const PromotionService = {
  async getSalesPromotions(
    data = { page: 1, promotions: null, user_id: null },
  ) {
    try {
      return await ApiService.get(
        `users-promotions/index?page=${data.page}${
          data.promotions ? '&promotions=' + data.promotions : ''
        }${data.user_id ? '&user_id=' + data.user_id : ''}`,
      )
    } catch (error) {
      return error
    }
  },

  async getSalePromotions(id) {
    try {
      return await ApiService.get(`users-promotions/${id}`)
    } catch (error) {
      return error
    }
  },

  async approveUserPromotion(id) {
    try {
      return await ApiService.put(`user-promotions/approve/${id}`)
    } catch (error) {
      return error
    }
  },

  async resultFilters() {
    try {
      return await ApiService.get(`user-promotions/filters`)
    } catch (error) {
      return error.response
    }
  },
}

export { PromotionService }
