const commands = [
  {
    path: 'ipc',
    name: 'command_ipc',
    icon: 'cil-list-high-priority',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/commands/Ipc.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
    },
  },
  {
    path: 'users',
    name: 'command_users',
    icon: 'cil-list-high-priority',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/commands/UserImport.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
    },
  },
  {
    path: 'import-adex',
    name: 'import_adex',
    icon: 'cil-list-high-priority',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/adex/Import.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
    },
  },
]

export default commands
