import messages from '@/assets/messages'
import { createI18n } from 'vue-i18n'

const lang = localStorage.getItem('currentLocale')

const i18n = createI18n({
  legacy: false,
  locale: lang,
  fallbackLocale: lang,
  globalInjection: true,
  messages,
})

export default i18n
