import ApiService from './api.service'

const BTrainingService = {
  async newTraining(data) {
    try {
      return await ApiService.post('btrainings', data)
    } catch (error) {
      return error.response
    }
  },
  async trainings(page = 1) {
    try {
      return await ApiService.get('btrainings?withDetail=0&page=' + page)
    } catch (error) {
      return error
    }
  },
  async training(id) {
    try {
      return await ApiService.get('btrainings/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },
  async updateTraining(data, id) {
    try {
      return await ApiService.put('btrainings/' + id, data)
    } catch (error) {
      return error.response
    }
  },
  async bTrainingTypes() {
    try {
      return await ApiService.get('bTrainingTypes')
    } catch (error) {
      return error.response
    }
  },
  async bTrainingCosts() {
    try {
      return await ApiService.get('bTrainingCosts')
    } catch (error) {
      return error.response
    }
  },
  async newBTrainingDetail(data) {
    try {
      return await ApiService.post('btrainings/details', data)
    } catch (error) {
      return error.response
    }
  },
  async bTrainingDetails(trainingId) {
    try {
      return await ApiService.get(`btrainings/details/langs/${trainingId}`)
    } catch (error) {
      return error.response
    }
  },
  async deleteBTrainingDetailById(trainingId) {
    try {
      const response = await ApiService.delete('btrainings/' + trainingId)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async approve(id) {
    try {
      return await ApiService.put(`btrainings/approve/${id}`)
    } catch (error) {
      return error
    }
  },
  async publish(id) {
    try {
      return await ApiService.put(`btrainings/publish/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async reorderTrainings(data) {
    try {
      return await ApiService.post('btrainings/reorder', data)
    } catch (error) {
      return error
    }
  },

  async archive(id) {
    try {
      return await ApiService.put(`btrainings/archive/${id}`)
    } catch (error) {
      return error.response
    }
  },
}

export { BTrainingService }
