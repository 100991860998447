import ApiService from './api.service'

const TrainingService = {
  async trainings(page = 1) {
    try {
      return await ApiService.get('trainings?withQuestion=0&page=' + page)
    } catch (error) {
      return error
    }
  },
  async approve(id) {
    try {
      return await ApiService.put(`trainings/approve/${id}`)
    } catch (error) {
      return error.response
    }
  },
  async createSlider(id) {
    try {
      return await ApiService.post(`trainings/${id}/create-slider`)
    } catch (error) {
      return error.response
    }
  },
  async publish(id) {
    try {
      return await ApiService.put(`trainings/publish/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async storeCertificateText(data) {
    try {
      return await ApiService.post(`trainings/certificate`, data)
    } catch (error) {
      return error.response.data
    }
  },

  async certificateTexts(id) {
    try {
      return await ApiService.get(`trainings/certificate/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async deleteCertificateById(id) {
    try {
      const response = await ApiService.delete(`trainings/certificate/${id}`)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async updateCertificateText(data) {
    try {
      return await ApiService.put(`trainings/certificate/${data.id}`, data)
    } catch (error) {
      return error.response
    }
  },

  async reorderCertificate(trainingId, data) {
    try {
      return await ApiService.post(
        `trainings/certificate/reorder/${trainingId}`,
        data,
      )
    } catch (error) {
      return error.response
    }
  },

  async resultFilters() {
    try {
      return await ApiService.get(`trainings/filters`)
    } catch (error) {
      return error.response
    }
  },

  async reorderTrainings(data) {
    try {
      return await ApiService.post('trainings/reorder', data)
    } catch (error) {
      return error
    }
  },

  async archive(id) {
    try {
      return await ApiService.put(`trainings/archive/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async updateTraining(data, id) {
    try {
      return await ApiService.put('trainings/' + id, data)
    } catch (error) {
      return error.response
    }
  },

  async training(id) {
    try {
      return await ApiService.get('trainings/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },

  async newTraining(data) {
    try {
      return await ApiService.post('trainings', data)
    } catch (error) {
      return error.response
    }
  },
}

export { TrainingService }
