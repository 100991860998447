const mutations = {
  SET_POINT_TYPE(state, pointType) {
    state.enterPointsManually = pointType
  },
  SET_EXTRA_POINTS(state, extraPoints) {
    state.extraPoints = extraPoints
  },
  SET_IMPORTER_STATE(state, importerState) {
    state.importerState['init'] = null
    delete state.importerState['init']
    state.importerState[importerState] = true
  },
  UNSET_IMPORTER_STATE(state, importerState) {
    state.importerState[importerState] = null
    delete state.importerState[importerState]
  },
  SET_SELECTED_USER(state, selectedUser) {
    state.selectedUser = selectedUser
  },
  SET_EXCEL_USERS(state, excelUsers) {
    state.excelUsers = excelUsers
  },
  SET_FETCHED_USERS(state, fetchedUsers) {
    state.fetchedUsers = fetchedUsers
  },
  SET_EXTRA_IMPORT_POINTS(state, extraImportPoints) {
    state.extraImportPoints = extraImportPoints
  },
  SET_IMPORT_POINTS(state, importPoints) {
    state.importPoints = importPoints
  },
  SET_IMPORTER_TYPE(state, type) {
    state.importerType = type
  },
  SET_IMPORTER_TYPES(state, types) {
    state.importerTypes = types
  },
}

export { mutations }
