import ApiService from './api.service'

const AdexService = {
  async newAdex(data) {
    try {
      return await ApiService.post('connections/adex/store', data)
    } catch (error) {
      return error.response
    }
  },
  async connections() {
    try {
      return await ApiService.get('connections/select')
    } catch (error) {
      return error
    }
  },
}

export { AdexService }
