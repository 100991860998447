const messages = [
  {
    path: 'messages',
    name: 'messages',
    icon: 'cil-short-text',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
    },
    children: [
      {
        path: '',
        showInMenu: true,
        name: 'messages_list',
        components: {
          content: () => import('@/views/pages/messages/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
      {
        path: 'new',
        showInMenu: true,
        name: 'messages_new',
        components: {
          content: () => import('@/views/pages/messages/New.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
      {
        path: ':id',
        name: 'messages_edit',
        components: {
          content: () => import('@/views/pages/messages/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'nl', 'en', 'ch'],
        },
      },
    ],
  },
  {
    path: 'threads',
    name: 'threads',
    icon: 'cil-chat-bubble',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/threads/Index.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
    },
  },
]

export default messages
