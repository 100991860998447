export default {
  message: {
    'points-importer': {
      label: 'Datei mit Punkten',
      undo: 'Zurücksetzen ({points} Punkte)',
      upload: 'Upload ({points} Punkte)',
      'importer-type': 'Importer-Typ',
      'import-name': 'Importbezeichnung',
      'switch-point-type': 'Datei-Upload / Manuelle Eingabe',
      table: {
        index: '',
        customerNumber: 'Kundennummer',
        pointsInList: 'Bestandspunkte',
        user: 'Kunde',
        points: 'Punkte aus der Liste',
        adds: 'Punkte neu',
        added: 'Punkte hinzugefügt',
        actions: 'Aktionen',
      },
      alert: {
        success: 'Punkte wurden erfolgreich importiert',
      },
    },
    'points-importer-types': {
      list: {
        table: {
          name: 'Name',
          description: 'Beschreibung',
        },
      },
    },
    menu: {
      'points-importer': 'Punkteimporter',
      'points-importer-config-importers': 'Konfiguration',
      'points-importer-import': 'Import',
    },
    breadcrumb: {
      'points-importer': 'Punkteimporter',
      'points-importer-config-importers': 'Konfiguration',
      'points-importer-config-edit-importer': 'Konfiguration editieren',
      'points-importer-import': 'Import',
    },
  },
}
