import ApiService from './api.service'

const OrderService = {
  async deleteOrderItem(id, type, notify) {
    try {
      const response = await ApiService.delete(
        `orderitem/${id}?type=${type}&notify=${notify}`,
      )
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async deleteOrderItems(id, notify) {
    try {
      const response = await ApiService.delete(
        `orderitems/${id}?notify=${notify}`,
      )
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async deleteOrder(id) {
    try {
      const response = await ApiService.delete(`order/${id}`)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async approveOrder(id) {
    try {
      return await ApiService.put(`order/approve/${id}`)
    } catch (error) {
      return error
    }
  },
  async approveUberall(id) {
    try {
      return await ApiService.put(`uberall/approve/${id}`)
    } catch (error) {
      return error
    }
  },

  async orders(data = { page: 1 }) {
    let params = `?page=${data.page}`
    if (data.id) {
      params += `&id=${data.id}`
    }
    if (data.dateFrom) {
      params += `&dateFrom=${data.dateFrom}`
    }

    if (data.dateTo) {
      params += `&dateTo=${data.dateTo}`
    }

    try {
      return await ApiService.get(`orders${params}`)
    } catch (error) {
      return error
    }
  },

  async getOrder(id) {
    try {
      return await ApiService.get(`orders/${id}`)
    } catch (error) {
      return error
    }
  },
}

export { OrderService }
