import ApiService from './api.service'

const SurveyService = {
  async storeSurvey(data) {
    try {
      return await ApiService.post('surveys', data)
    } catch (error) {
      return error.response
    }
  },
  async surveys(page = 1) {
    try {
      return await ApiService.get('surveys?page=' + page)
    } catch (error) {
      return error.response
    }
  },
  async surveysActivated() {
    try {
      return await ApiService.get('surveys/activated')
    } catch (error) {
      return error.response
    }
  },
  async survey(id) {
    try {
      return await ApiService.get('surveys/' + id + '/show/langs')
    } catch (error) {
      return error.response
    }
  },
  async updateSurvey(data, id) {
    try {
      return await ApiService.put('surveys/' + id, data)
    } catch (error) {
      return error.response
    }
  },
  async setActivatedSurvey(id) {
    try {
      return await ApiService.post('surveys/activated/' + id)
    } catch (error) {
      return error.response
    }
  },

  async removeSurvey(surveyId) {
    try {
      const response = await ApiService.delete('surveys/' + surveyId)
      return response
    } catch (error) {
      return error.response.data
    }
  },
}

export { SurveyService }
