import TokenService from './storage.service'
import router from '../router'
import axios from 'axios'
import store from '@/store/index'
import i18n from '@/plugins/i18n'
import { toast } from 'vue3-toastify'

const LANG = localStorage.getItem('lang')
const currentLocale = localStorage.getItem('currentLocale')

const ApiService = {
  init(baseURL) {
    axios.defaults.headers.common['Accept-Language'] = LANG
    axios.defaults.headers.common['GW-Language'] = currentLocale
    axios.defaults.baseURL = baseURL
    // axios.defaults.withCredentials = true
    axios.interceptors.response.use(
      (response) => {
        toast.clearAll()
        store.dispatch('addPending', false)
        if (response.config.method != 'get') {
          toast(i18n.global.t('message.toastMessages.success'), {
            type: 'success',
            theme: 'colored',
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.TOP_RIGHT,
          })
        }
        return response
      },
      function (error) {
        store.dispatch('addPending', false)
        toast(i18n.global.t('message.toastMessages.error'), {
          type: 'error',
          theme: 'colored',
          transition: toast.TRANSITIONS.SLIDE,
          position: toast.POSITION.TOP_RIGHT,
        })
        if (error.response.status === 401) {
          router.push('/error')
        }
        return Promise.reject(error)
      },
    )

    axios.interceptors.request.use((config) => {
      store.dispatch('addPending', true)
      config.headers.common.Authorization = `Bearer ${TokenService.getToken()}`

      return config
    })
  },

  setHeader(contentType = 'application/json') {
    axios.defaults.headers.common['Content-Type'] = contentType
    axios.defaults.withCredentials = true
  },

  removeHeader() {
    axios.defaults.headers.common = {}
  },

  get(resource) {
    return axios.get('/api/' + LANG + '/' + resource)
  },

  csrfCookie() {
    return axios.get('/sanctum/csrf-cookie')
  },

  post(resource, data, headers) {
    return axios.post('/api/' + LANG + '/' + resource, data, headers)
  },

  put(resource, data) {
    return axios.put('/api/' + LANG + '/' + resource, data)
  },

  delete(resource) {
    return axios.delete('/api/' + LANG + '/' + resource)
  },

  patch(resource) {
    return axios.patch('/api/' + LANG + '/' + resource)
  },

  customRequest(data) {
    return axios(data)
  },
}

export default ApiService
