import ApiService from './api.service'

const StatService = {
  async trainingResults(data = { page: 1, training_id: null, user_id: null }) {
    try {
      return await ApiService.get(
        `trainings-results?page=${data.page}${
          data.training_id ? '&training_id=' + data.training_id : ''
        }${data.user_id ? '&user_id=' + data.user_id : ''}`,
      )
    } catch (error) {
      return error
    }
  },

  async bTrainingResults(page = 1) {
    try {
      return await ApiService.get('btrainings-results?page=' + page)
    } catch (error) {
      return error
    }
  },

  async externalTrainingResults(page = 1) {
    try {
      return await ApiService.get('external-trainings-results?page=' + page)
    } catch (error) {
      return error
    }
  },

  async investmentResults(page = 1) {
    try {
      return await ApiService.get('investment-results?page=' + page)
    } catch (error) {
      return error
    }
  },

  async hunterResults(page = 1) {
    try {
      return await ApiService.get('hunter-results?page=' + page)
    } catch (error) {
      return error
    }
  },

  async uberallResults(page = 1) {
    try {
      return await ApiService.get('uberall-results?page=' + page)
    } catch (error) {
      return error
    }
  },

  async requestCounts() {
    try {
      return await ApiService.get('request-count-stats')
    } catch (error) {
      return error
    }
  },

  async getIsNewOrderItems() {
    try {
      return await ApiService.get('is-new-order-items')
    } catch (error) {
      return error
    }
  },
}

export { StatService }
