import ApiService from './api.service'

const VoucherService = {
  async select() {
    try {
      return await ApiService.get('voucher/select')
    } catch (error) {
      return error.response
    }
  },
  async create(data) {
    try {
      return await ApiService.post('admin/voucher/create', data)
    } catch (error) {
      return error.response
    }
  },
  async vouchers(data = { page: 1, manager_id: '' }) {
    try {
      return await ApiService.get(
        `voucher-list?page=${data.page}&manager_id=${data.manager_id}`,
      )
    } catch (error) {
      return error.response
    }
  },
  async delete(id) {
    try {
      const response = await ApiService.delete(`admin/voucher/${id}/delete`)
      return response
    } catch (error) {
      return error.response.data
    }
  },
}

export { VoucherService }
