import ApiService from './api.service'

const CategoryService = {
  async storeCategory(data) {
    try {
      return await ApiService.post('categories', data)
    } catch (error) {
      return error
    }
  },

  async removeCategory(id) {
    try {
      return await ApiService.delete(`categories/${id}`)
    } catch (error) {
      return error
    }
  },

  async saveCategory(data, id) {
    try {
      return await ApiService.put('categories/' + id, data)
    } catch (error) {
      return error
    }
  },

  async category(id) {
    try {
      return await ApiService.get('categories/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },

  async categories() {
    try {
      return await ApiService.get('categories')
    } catch (error) {
      return error
    }
  },

  async categoriesForSelect() {
    try {
      return await ApiService.get('categories/select')
    } catch (error) {
      return error
    }
  },

  async categoryExternals() {
    try {
      return await ApiService.get('category_externals')
    } catch (error) {
      return error
    }
  },

  async storeCategoryExternalMatchings(data) {
    try {
      return await ApiService.post('categories/update_externals', data)
    } catch (error) {
      return error
    }
  },
}

export { CategoryService }
