import ApiService from './api.service'

const NewsFeedService = {
  async newNewsFeed(data) {
    try {
      return await ApiService.post('newsFeeds', data)
    } catch (error) {
      return error.response
    }
  },
  async newsFeeds(page = 1) {
    try {
      return await ApiService.get('newsFeeds?page=' + page)
    } catch (error) {
      return error
    }
  },
  async newsFeed(id) {
    try {
      return await ApiService.get('newsFeeds/' + id + '/show/langs')
    } catch (error) {
      return error.response
    }
  },
  async updateNewsFeed(id, data) {
    try {
      return await ApiService.put('newsFeeds/' + id, data)
    } catch (error) {
      return error.response
    }
  },
  async deleteNewsFeedById(id) {
    try {
      const response = await ApiService.delete('newsFeeds/' + id)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async newNewsFeedSlide(data) {
    try {
      return await ApiService.post('newsFeeds/slider', data)
    } catch (error) {
      return error.response
    }
  },

  async deleteNewsFeedSlide(id) {
    try {
      const response = await ApiService.delete(`newsFeeds/slider/${id}`)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async updateNewsFeedSlide(id, data) {
    try {
      return await ApiService.put(`newsFeeds/slider/${id}`, data)
    } catch (error) {
      return error.response
    }
  },

  async reorderNews(data) {
    try {
      return await ApiService.post('newsFeeds/reorder', data)
    } catch (error) {
      return error
    }
  },
}

export { NewsFeedService }
