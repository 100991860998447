import ApiService from './api.service'
import i18n from '@/plugins/i18n'

const ProfileService = {
  async updateField(data) {
    try {
      return await ApiService.put(`profile/update/${data.user_id}/field`, data)
    } catch (error) {
      return error
    }
  },
  async doMatch(user_id) {
    try {
      return await ApiService.put(`profile/do_match/${user_id}`)
    } catch (error) {
      return error
    }
  },
  getMessageByStatusIban(data, admin_do_match) {
    var text_validate = '<div style="width:100%; padding:15px;">'
    if (admin_do_match) {
      text_validate = ''
    }
    if (data.nameMatchResult != '') {
      text_validate +=
        '<li style="text-align: left; ">' +
        i18n.global.t(
          'message.iban_status.nameMatchResult.' + data.nameMatchResult,
        ) +
        '</li>'
    }
    if (data.dataUsedForMatching != '') {
      text_validate +=
        '<li style="text-align: left; ">' +
        i18n.global.t(
          'message.iban_status.dataUsedForMatching.' + data.dataUsedForMatching,
        ) +
        '</li>'
      text_validate +=
        '<li style="text-align: left; ">' +
        i18n.global.t(
          'message.iban_status.accountNumberValidation.' +
            data.accountNumberValidation,
        ) +
        '</li>'
      text_validate +=
        '<li style="text-align: left; ">' +
        i18n.global.t(
          'message.iban_status.paymentPreValidation.' +
            data.paymentPreValidation,
        ) +
        '</li>'
      text_validate +=
        '<li style="text-align: left; ">' +
        i18n.global.t('message.iban_status.status.' + data.status) +
        '</li>'
    }
    if (data.errorCode) {
      text_validate +=
        '<li style="text-align: left; ">' +
        data.errorCode +
        ' - ' +
        data.errorMessage
      ;('</li>')
    }

    text_validate += '</div>'
    return text_validate
  },
}
export { ProfileService }
