<template>
    <CModalHeader>
        <CModalTitle>Maintenance mode</CModalTitle>
    </CModalHeader>
    <CModalBody>
        Are you sure you want to continue? When enabled, the site will be temporarily unavailable to users.
    </CModalBody>
    <CModalFooter>
        <CButton color="danger" @click="close(false)">Cancel</CButton>
        <CButton color="primary" @click="setMaintenance()">Confirm</CButton>
    </CModalFooter>
</template>

<script setup>
import { defineEmits } from 'vue'

import { AppService } from '@/services/app.service'

const emit = defineEmits(['close'])
const setMaintenance = async () => {
    await AppService.createMaintenance()
    close(true)

}

const close = (value) => {
    emit('close', value)
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>