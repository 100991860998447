import ApiService from './api.service'

const WarehouseService = {
  async newStore(data) {
    try {
      return await ApiService.post('warehouses', data)
    } catch (error) {
      return error.response
    }
  },
  async warehouses(data) {
    try {
      return await ApiService.get(
        `warehouses/${data.productId}?page=${data.page}`,
      )
    } catch (error) {
      return error.response
    }
  },

  async updateWarehouse(id, data) {
    try {
      return await ApiService.put(`warehouses/${id}`, data)
    } catch (error) {
      return error.response
    }
  },
}

export { WarehouseService }
