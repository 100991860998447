<template>
  <router-view />
</template>

<script setup>
import 'vue3-toastify/dist/index.css'
import { ref } from 'vue'

const firstLoad = ref(true)
setInterval(() => {
  if (firstLoad.value) {
    firstLoad.value = false
  } else {
    window.location.reload(true)
  }
}, 3600000)
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
