const getters = {
  pointType: (state) => state.enterPointsManually,
  extraPoints: (state) => state.extraPoints,
  importerState: (state) => state.importerState,
  excelUsers: (state) => state.excelUsers,
  selectedUser: (state) => state.selectedUser,
  fetchedUsers: (state) => state.fetchedUsers,
  importPoints: (state) => state.importPoints,
  extraImportPoints: (state) => state.extraImportPoints,
  importerType: (state) => state.importerType,
  importerTypes: (state) => state.importerTypes,
}

export { getters }
