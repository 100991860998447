const state = {
  importerState: {init: true},
  enterPointsManually: false,
  importPoints: 0,
  extraImportPoints: 0,
  selectedUser: null,
  excelUsers: [],
  fetchedUsers: [],
  importerType: [],
  importerTypes: [],
  extraPoints: [],
}

export { state }
