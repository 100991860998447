const points = [
  {
    path: 'cheques',
    name: 'cheques',
    icon: 'cil-spreadsheet',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/cheque/New.vue'),
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
  },
  {
    path: 'importers',
    name: 'points-importer',
    icon: 'cil-cloud-upload',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'ch'],
    },
    children: [
      {
        path: 'importer-types',
        name: 'points-importer-config-importers',
        icon: 'cil-settings',
        showInMenu: false, // true
        components: {
          content: () =>
            import(
              '@/plugins/points-importer/view/pages/importer-types/List.vue'
            ),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
      {
        path: 'importer-types/:id',
        name: 'points-importer-config-edit-importer',
        showInMenu: false,
        components: {
          content: () =>
            import(
              '@/plugins/points-importer/view/pages/importer-types/Edit.vue'
            ),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
      {
        path: 'importer-types',
        name: 'points-importer-config-add-importer',
        showInMenu: false,
        components: {
          content: () =>
            import(
              '@/plugins/points-importer/view/pages/importer-types/New.vue'
            ),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
      {
        path: '',
        name: 'points-importer-import',
        icon: 'cil-stream',
        showInMenu: true,
        components: {
          content: () =>
            import('@/plugins/points-importer/view/pages/Import.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin'],
          requiredLang: ['de', 'ch'],
        },
      },
    ],
  },
  {
    path: 'booster',
    name: 'booster',
    icon: 'cil-list-high-priority',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'ch', 'nl'],
    },
    children: [
      {
        path: '',
        name: 'booster_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/booster/List.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: 'new',
        showInMenu: true,
        name: 'booster_setup',
        components: {
          content: () => import('@/views/pages/booster/New.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
      {
        path: 'preview',
        showInMenu: true,
        name: 'booster_preview',
        components: {
          content: () => import('@/views/pages/booster/Preview.vue'),
        },
        meta: {
          requiredRole: ['admin'],
          requiredLang: ['de', 'ch', 'nl'],
        },
      },
    ],
  },
]

export default points
