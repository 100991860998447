import ApiService from './api.service'

const MenuService = {
  async filter(roleId = 0) {
    try {
      return await ApiService.get(`menus/frontend/${roleId}`)
    } catch (error) {
      return error
    }
  },

  async reorderMenu(data) {
    try {
      return await ApiService.post('menus/frontend/reorder', data)
    } catch (error) {
      return error
    }
  },

  async deleteMenu(id) {
    try {
      const response = await ApiService.delete(`menus/frontend/delete/${id}`)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async links(roleId = 0) {
    try {
      return await ApiService.get(`menus/frontend/links/${roleId}`)
    } catch (error) {
      return error
    }
  },

  async storeLink(data) {
    try {
      return await ApiService.post('menus/frontend/links/store', data)
    } catch (error) {
      return error
    }
  },

  async updateLink(data) {
    try {
      return await ApiService.put(
        `menus/frontend/${data.id}/links/update`,
        data,
      )
    } catch (error) {
      return error
    }
  },
}

export { MenuService }
