import ApiService from './api.service'

const MessageService = {
  async getMessages(data = '') {
    try {
      return await ApiService.get(`messages/langs${data}`)
    } catch (error) {
      return error
    }
  },

  async getMessage(id) {
    try {
      return await ApiService.get(`message/${id}/show/langs`)
    } catch (error) {
      return error
    }
  },

  async storeMessage(data) {
    try {
      return await ApiService.post(`messages`, data)
    } catch (error) {
      return error
    }
  },

  async saveMessage(data, id) {
    try {
      return await ApiService.put(`messages/${id}`, data)
    } catch (error) {
      return error
    }
  },

  async deleteMessage(id) {
    try {
      return await ApiService.delete(`messages/${id}`)
    } catch (error) {
      return error
    }
  },
}

export { MessageService }
