import ApiService from './api.service'

const HunterService = {
  async newHunter(data) {
    try {
      return await ApiService.post('hunterProduct/detail', data)
    } catch (error) {
      return error.response
    }
  },
  async hunterDetails() {
    try {
      return await ApiService.get('hunterProducts')
    } catch (error) {
      return error.response
    }
  },
  async hunterDetail(id) {
    try {
      return await ApiService.get(`hunterProduct/detail/${id}/show/langs`)
    } catch (error) {
      return error
    }
  },
  async updateHunterDetail(data, id) {
    try {
      return await ApiService.put(`hunterProduct/detail/${id}`, data)
    } catch (error) {
      return error.response
    }
  },
  async approve(id) {
    try {
      return await ApiService.put(`hunterProduct/approve/${id}`)
    } catch (error) {
      return error
    }
  },
}

export { HunterService }
