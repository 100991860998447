import ApiService from './api.service'

const BoosterService = {
  async boosters(page = 1) {
    try {
      return await ApiService.get(`boosters?page=${page}`)
    } catch (error) {
      return error
    }
  },
  async activeInactive(id) {
    try {
      return await ApiService.put(`boosters/active/${id}`)
    } catch (error) {
      return error
    }
  },
  async inactiveUserBoosters(userId) {
    try {
      return await ApiService.get(`users/inactive/boosters/${userId}`)
    } catch (error) {
      return error
    }
  },
  async boostersPreview() {
    try {
      return await ApiService.get(`users/boosters/approve`)
    } catch (error) {
      return error
    }
  },

  async activeBoostersForUser(data) {
    try {
      return await ApiService.put(
        `users/active/boosters/${data.userId}?boosterId=${data.boosterId}`,
      )
    } catch (error) {
      return error
    }
  },

  async fetchUserBoosterSelect(userId) {
    try {
      return await ApiService.get(`users/boosters/list/${userId}`)
    } catch (error) {
      return error
    }
  },

  async calculateBoosters(id) {
    try {
      return await ApiService.post(`user/boosters/import/${id}`)
    } catch (error) {
      return error
    }
  },

  async updateBoosterCronStatus(id, status) {
    try {
      return await ApiService.put(
        `users/boosters/${id}/status?status=${status}`,
      )
    } catch (error) {
      return error
    }
  },

  async boosterFields() {
    try {
      return await ApiService.get('boosters/fields')
    } catch (e) {
      return e.response
    }
  },

  async createBooster(data) {
    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return await ApiService.post('boosters/create', data, config)
    } catch (e) {
      return e.response
    }
  },
}

export { BoosterService }
