export default {
  message: {
    customerNumbersAssistant: {
      date: {
        title: 'Calculate points from date X',
        warning: 'With a selected date, main business numbers cannot be moved!',
      },
    },
  },
}
