import ApiService from './api.service'

const StatusService = {
  async newStatus(data) {
    try {
      return await ApiService.post('status', data)
    } catch (error) {
      return error.response
    }
  },
}
export { StatusService }
