import ApiService from './api.service'

const MediaService = {
  async mediaCategories() {
    try {
      return await ApiService.get('media-categories')
    } catch (error) {
      return error.response
    }
  },
  async newMedia(data) {
    try {
      return await ApiService.post('admin/media', data)
    } catch (error) {
      return error.response
    }
  },
  async medias(page = 1) {
    try {
      return await ApiService.get(`medias?page=${page}`)
    } catch (error) {
      return error.response
    }
  },
  async deleteMediaPageById(id) {
    try {
      const response = await ApiService.delete(`admin/media/${id}`)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async mediaPage(id) {
    try {
      return await ApiService.get(`medias/${id}/show/langs`)
    } catch (error) {
      return error.response
    }
  },
  async updateMediaPage(data, id) {
    try {
      return await ApiService.put(`admin/media/update/${id}`, data)
    } catch (error) {
      return error.response
    }
  },
  async storeFiles(data, id) {
    try {
      ApiService.setHeader('application/x-www-form-urlencoded')
      return await ApiService.post('admin/media/files/' + id, data)
    } catch (error) {
      return error.response
    }
  },

  async mediaPageFiles(id) {
    try {
      return await ApiService.get(`medias/${id}/files/langs`)
    } catch (error) {
      return error.response
    }
  },

  async deleteMediaPageFile(id) {
    try {
      const response = await ApiService.delete(`admin/media/${id}/file`)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async storeAdexFiles(data) {
    try {
      ApiService.setHeader('application/x-www-form-urlencoded')
      return await ApiService.post('admin/adex/files', data)
    } catch (error) {
      return error.response
    }
  },

  async storeBanner(data) {
    try {
      ApiService.setHeader('application/x-www-form-urlencoded')
      return await ApiService.post('admin/default-banners', data)
    } catch (error) {
      return error.response
    }
  },

  async defaultBanners() {
    try {
      return await ApiService.get(`admin/default-banners`)
    } catch (error) {
      return error.response
    }
  },

  async deleteDefaultBannerById(id) {
    try {
      const response = await ApiService.delete(`admin/default-banners/${id}`)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async reorderBanners(data) {
    try {
      return await ApiService.post('admin/reorder-banners', data)
    } catch (error) {
      return error
    }
  },

  async fetchFilteredMedia(data) {
    return await ApiService.post(`media/filter`, data)
  },
}

export { MediaService }
