import ApiService from './api.service'

const MainBoxService = {
  async store(data) {
    try {
      const response = await ApiService.post('admin/main-boxes', data)
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async getMainBoxes(data = 'page=1&roleId=') {
    try {
      return await ApiService.get(`admin/main-boxes?${data}`)
    } catch (error) {
      return error.response
    }
  },

  async getMainBox(id) {
    try {
      return await ApiService.get(`admin/main-boxes/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async deleteMainBox(id) {
    try {
      const response = await ApiService.delete(`admin/main-boxes/${id}/delete`)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async updateMainBox(data) {
    try {
      return await ApiService.put(`admin/main-boxes/${data.id}/update`, data)
    } catch (error) {
      return error.response
    }
  },

  async reorder(data) {
    try {
      return await ApiService.post('admin/reorder-main-boxes', data)
    } catch (error) {
      return error.response
    }
  },
}

export { MainBoxService }
