<template>
  <div>
    <CSidebar
      :visible="visible"
      :class="{ show: visible == true, hide: visible == false }"
      @visible-change="store.dispatch('setMenuVisible', $event)"
      position="fixed"
      class="sidebar--width"
    >
      <CSidebarBrand>
        <CCloseButton
          white
          class="close mx-2"
          @click="store.dispatch('setMenuVisible', false)"
        />
      </CSidebarBrand>
      <CSidebarNav>
        <template v-for="(link, id) in links" :key="`parent_${id}`">
          <MenuItems :link="link" v-if="hasPermission"></MenuItems>
        </template>
      </CSidebarNav>
    </CSidebar>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view name="content" />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script setup>
import { CContainer, CSidebar } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import MenuItems from '@/components/MenuItems.vue'
import { computed } from '@vue/reactivity'

const store = useStore()
const router = useRouter()
let user = store.getters.getAdminMe

const links = router.getRoutes().filter((item) => item.meta.isParent == true)

store.dispatch('me')

const showSidebar = () => {
  const width = window.innerWidth
  if (width > 800) {
    store.dispatch('setMenuVisible', true)
  } else {
    store.dispatch('setMenuVisible', false)
  }
}

const hasPermission = (link) => {
  let role = user.roles.find((item) =>
    link.meta.requiredRole.includes(item.name),
  )

  if (role) {
    return true
  }

  return false
}

const visible = computed(() => store.getters.getMenuVisible)

window.addEventListener('resize', showSidebar)
</script>

<style lang="scss" scoped>
.close {
  display: none;
  @media screen and (max-width: 800px) {
    display: flex;
  }
}
:deep(.sidebar-brand) {
  justify-content: end;
}
</style>
