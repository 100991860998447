import ApiService from './api.service'

const WholesalerService = {
  async wholesalerManagements() {
    try {
      return await ApiService.get('admin/wholesalers/list')
    } catch (error) {
      return error
    }
  },
  async updateWholesalerManagement(data) {
    try {
      return await ApiService.put(`admin/wholesalers/update/${data.id}`, data)
    } catch (error) {
      return error.response
    }
  },

  async getWhsStaffWholesaler(id) {
    try {
      return await ApiService.get(`whsstaff-wholesalers/${id}`)
    } catch (error) {
      return error
    }
  },
}

export { WholesalerService }
